// MenuSection.js
import React from 'react';

const MenuSection = ({ title, items }) => {
  return (
    <div className="menu-section">
      <h2 className="menu-title">{title}</h2>
      <div className="menu-items">
        {items.map((item, index) => (
          <div key={index} className="menu-item">
            <p>{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuSection;
