import { Link } from 'react-router-dom';  

import { Helmet } from 'react-helmet';
export default function Attraction(){
  const teamMembers = [
    {
      id: 1,
      name: 'Swimming Pool',
      role: 'Glowry Stone Resort',
      image: 'glory stone images/Glowry Stone Resort/Glowry Stone Resort Swimming Pool.jpg',
    },
    {
      id: 2,
      name: 'Tasty Food',
      role: 'Glowry Stone Resort',
      image: 'glory stone images/Glowry Stone Resort/Glowry Stone Resort dish.jpg',
    },
    {
      id: 3,
      name: 'Resort Bedroom',
      role: 'Glowry Stone Resort',
      image: 'glory stone images/Glowry Stone Resort/Glowry Stone Resort bedroom.jpg',
    },
    {
      id: 4,
      name: 'Resort Area',
      role: 'Glowry Stone Resort',
      image: 'glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery9.jpg',
    },

  ];


    const TrendingItem = ({ image, discount, location, title, days, price, rating, link }) => {
        return (
          <div className="col-lg-6 mb-4">
            <div className="trend-full bg-white rounded box-shadow overflow-hidden">
              <div className="row m-0">
                <div className="col-lg-5 col-md-4 ps-0">
                  <div className="trend-item2">
                    <a href={link} style={{ backgroundImage: `url(${image})` }}></a>
                    <div className="ribbon ribbon-top-left">
                      <span className="fw-bold">{discount} OFF</span>
                    </div>
                    <div className="color-overlay"></div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-8">
                  <div className="trend-content py-3 position-relative">
                    <h5 className="theme mb-1">
                      <i className="flaticon-location-pin"></i> {location}
                    </h5>
                    <h3 className="mb-1"><a href={link}>{title}</a></h3>
                    <div className="rating-main d-flex align-items-center pb-2">
                      <div className="rating">
                        {Array.from({ length: 5 }, (_, i) => (
                          <span key={i} className={`fa fa-star ${i < rating ? 'checked' : ''}`}></span>
                        ))}
                      </div>
                      <span className="ms-2">({rating})</span>
                    </div>
                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum</p>
                    <div className="trend-meta border-b pb-2 mb-2">
                      <div className="entry-author theme">
                        <i className="icon-calendar"></i>
                        <span> {days} Days Tours</span>
                      </div>
                    </div>
                    <div className="entry-meta">
                      <div className="entry-author d-flex align-items-center">
                        <p className="mb-0"><span className="theme fw-bold fs-5"> ${price}</span> | Per person</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      };



      const trendingItems = [
        {
          image: 'images/trending/trending1.jpg',
          discount: '20%',
          location: 'Croatia',
          title: 'Piazza Castello',
          days: 9,
          price: 170,
          rating: 5,
          link: 'grid-leftfilter.html'
        },
        {
          image: 'images/trending/trending2.jpg',
          discount: '10%',
          location: 'Greece',
          title: 'Santorini, Oia',
          days: 7,
          price: 210,
          rating: 5,
          link: 'grid-leftfilter.html'
        },
        {
          image: 'images/trending/trending3.jpg',
          discount: '30%',
          location: 'Norway',
          title: 'Norway Lake',
          days: 6,
          price: 185,
          rating: 5,
          link: 'grid-leftfilter.html'
        },
        {
          image: 'images/trending/trending4.jpg',
          discount: '15%',
          location: 'Maldives',
          title: 'Hurawalhi Island',
          days: 4,
          price: 260,
          rating: 5,
          link: 'grid-leftfilter.html'
        }
      ];
    
      
    const articles = [
        {
          id: 1,
          category: 'Glowry Stone Resort',
          title: 'Scenic Nature Trails',
          description: 'Discover the breathtaking beauty of our nature trails. Explore lush forests, rolling hills, and panoramic views while enjoying the serenity of the outdoors',
          image: 'glory stone images/Glowry Stone Resort Activity/Trekking.jpg',
        },
        {
          id: 1,
          category: 'Glowry Stone Resort',
          title: 'Bonfire Nights with BBQ',
          description: 'Gather around a cozy bonfire in the evening and enjoy a BBQ with your loved ones while the stars light up the night sky.',
          image: 'glory stone images/Glowry Stone Resort Activity/Campfire.jpg',
        },
        {
          id: 1,
          category: 'Glowry Stone Resort',
          title: 'Thrilling Adventure Activities',
          description: 'For the adventure seekers, enjoy activities like rock climbing, zip-lining, and ATV rides that will surely get your adrenaline pumping!',
          image: 'glory stone images/Glowry Stone Resort Activity/Zip Line.jpg',
        },
      ];
    
    return(
        <>
        <Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - Attraction | One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune | Camping Places Near Pune
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Attraction | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.jpg",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>
          <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3">Attraction</h1>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                   <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Attraction
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>
    
    <section className="trending recent-articles pb-6">
      <div className="container">
        <div className="section-title mb-6 w-75 mx-auto text-center">
     
          <h2 className="mb-1">Attraction of <span className="theme"> Glowry Stone Resort</span></h2>
           
        </div>
        <div className="recent-articles-inner">
          <div className="row">
            {articles.map((article) => (
              <div key={article.id} className="col-lg-4 col-md-6">
                <div className="trend-item box-shadow bg-white mb-4 rounded overflow-hidden">
                  <div className="trend-image">
                    <img src={article.image} alt="image" />
                  </div>
                  <div className="trend-content-main p-4 pb-2">
                    <div className="trend-content">
                      <h5 className="theme mb-1">{article.category}</h5>
                      <h4>
                        <a href={article.link}>{article.title}</a>
                      </h4>
                      <p className="mb-3">{article.description}</p>
                      <div className="entry-meta d-flex align-items-center justify-content-between">
                        {/* <div className="entry-author mb-2">
                          <img src={article.authorImage} alt={article.author} className="rounded-circle me-1" />
                          <span>{article.author}</span>
                        </div> */}
                        <div className="entry-button d-flex align-items-center mb-2">
                          <Link to="https://api.whatsapp.com/send/?phone=917066665554&text=Welcome to Glowry Stone Resort !!!!."  className="nir-btn">Book Now</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container">
       
          <div className="row shop-slider">
            {teamMembers.map((member) => (
              <div key={member.id} className="col-lg-3 col-md-6 col-sm-12 mb-4">
                <div className="team-list rounded">
                  <div className="team-image">
                    <img src={member.image} alt={member.name} />
                  </div>
                  <div className="team-content text-center p-3 bg-theme">
                    <h4 className="mb-0 white">{member.name}</h4>
                    <p className="mb-0 white">{member.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div> 
          </div>
    </section>





  
    {/* ============================= */}



        </>
    )
}