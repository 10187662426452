import { Link } from "react-router-dom";
import Card from "./Card";
import { Helmet } from "react-helmet";
export default function Footer(){
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  // Format the date to something like "Tuesday, November 19, 2024"
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString('en-US', options);

    return(
        <>

<Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - Footer| One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune | Camping Places Near Pune
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Home | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.png",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>
           <footer className="pt-20 pb-4" style={{ backgroundImage: 'url(images/background_pattern.png)' }}>
      <div className="section-shape top-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>

      <Card></Card>

      {/* Footer Upper Section */}
      <div className="footer-upper pb-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4 pe-4">
              <div className="footer-about">
                <img src="logo.jpg" alt="Glowry Stone Resort" />
                <p className="mt-3 mb-3 white">
                Glowry Stone Resort is an idyllic retreat nestled in the heart of nature, just a short drive from Pune, offering guests a perfect blend of relaxation, adventure, and luxury.
                </p>
                <ul>
                  <li className="white"><strong>Contact No :- </strong> +91 7066665554</li>
                  <li className="white"><strong>Location:</strong> Glowry Stone Resort
Wadegavhan, Pune-Nagar Road,
Tal-.Parner,Dist- Ahilya Nagar (Ahmednagar).
 Maharashtra - India - 414302.</li>
                  <li className="white"><strong>Email:</strong> <a href="mailto: reservation@glowrystone.com"> reservation@glowrystone.com</a></li>
                

                  <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d382292.29804668436!2d73.85454154939082!3d18.71613432609059!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc3330078197ac1%3A0x85eb9ad21caa9398!2sGlowry%20Stone%20Resort%20and%20Hotel!5e1!3m2!1sen!2sin!4v1731736229129!5m2!1sen!2sin"
        width="600"
        height="300"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className="footer-links">
                <h3 className="white">Services</h3>
                <ul>
                  <li><Link to='One-Day-Picnic-Spot-Near-Ahmednagar-for-Family'><i class="fas fa-umbrella-beach"></i> One Day Picnic Spot Near Ahmednagar for Family</Link></li>
                 <li><Link to='One-Day-Trip-Near-Ahmednagar'><i class="fas fa-umbrella-beach"></i> One Day Trip Near  Ahmednagar </Link></li>  
                 <li><Link to='Picnic-Spot-Near-Ahmednagar'><i class="fas fa-umbrella-beach"></i> Picnic Spot Near  Ahmednagar  </Link></li>  
                 <li><Link to='Picnic-Destination-Near-Pune'><i class="fas fa-umbrella-beach"></i> Picnic Destination Near Pune </Link></li>  
                 <li><Link to='Corporate-Outing-Resorts-Near-Ahmednagar'><i class="fas fa-umbrella-beach"></i> Corporate Outing Resorts Near  Ahmednagar  </Link></li>  
                 <li><Link to='Glowry-Stone-Resort-Near-Pune'><i class="fas fa-umbrella-beach"></i> Glowry Stone Resort Near Pune</Link></li>  
                 <li><Link to='Destination-Wedding-Near-Ahmednagar'><i class="fas fa-umbrella-beach"></i> Destination Wedding Near  Ahmednagar</Link></li>  
                 <li><Link to='Hurda-Party-Places-Near-Ahmednagar'><i class="fas fa-umbrella-beach"></i> Hurda Party Places Near  Ahmednagar</Link></li>  
                 <li><Link to='Water-Park-Near-Ahmednagar'><i class="fas fa-umbrella-beach"></i> Water Park Near  Ahmednagar</Link></li>  
                 <li><Link to='School-Picnic-Spots-Near-Ahmednagar-for-Students'><i class="fas fa-umbrella-beach"></i> School Picnic Spots Near  Ahmednagar  for Students </Link></li>  
                 <li><Link to='One-Day-Adventure-Resorts-Near-Ahmednagar'><i class="fas fa-umbrella-beach"></i> One Day Adventure Resorts Near  Ahmednagar  </Link></li>
                 <li><Link to='Resort-Near-Pune'><i class="fas fa-umbrella-beach"></i> Resort Near Pune</Link></li> 
                
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className="footer-links">
                <h3 className="white">Services</h3>
                <ul>
      
                 <li><Link to='Forest-Resorts-Near-Ahmednagar'><i class="fas fa-umbrella-beach"></i> Forest Resorts Near  Ahmednagar  </Link></li> 
                 <li><Link to='Nature-resorts-near-Ahmednagar-for-weekend'><i class="fas fa-umbrella-beach"></i> Nature resorts near  Ahmednagar  for weekend </Link></li> 
                 <li><Link to='Mango-Mahotsav-in-Ahmednagar'><i class="fas fa-umbrella-beach"></i> Mango Mahotsav in  Ahmednagar </Link></li> 
                 <li><Link to='Camping-Places-Near-Ahmednagar'><i class="fas fa-umbrella-beach"></i> Camping Places Near  Ahmednagar  </Link></li> 
                 <li><Link to='Activities-Resort-Near-Pune'><i class="fas fa-umbrella-beach"></i> Activities Resort Near Pune </Link></li> 
                 <li><Link to='Glowry-Stone-resort-Near-Mumbai-for-Family'><i class="fas fa-umbrella-beach"></i> Glowry Stone resort Near Mumbai for Family  </Link></li> 
                 <li><Link to='One-Day-Picnic-Spot-From-Mumbai'><i class="fas fa-umbrella-beach"></i> One Day Picnic Spot From Mumbai </Link></li> 
                 <li><Link to='One-Day-Picnic-Packages-Near-Mumbai'><i class="fas fa-umbrella-beach"></i> One Day Picnic Packages Near Mumbai </Link></li> 
                 <li><Link to='School-Picnic-Spots-in-Mumbai'><i class="fas fa-umbrella-beach"></i> School Picnic Spots in Mumbai </Link></li> 
                 <li><Link to='Resort-Near-Mumbai'><i class="fas fa-umbrella-beach"></i> Resort Near Mumbai</Link></li> 
                 <li><Link to='Team-Outing-Places-Near-Mumbai'><i class="fas fa-umbrella-beach"></i> Team Outing Places Near Mumbai</Link></li> 
                 <li><Link to='Nature-resorts-near-pune-for-weekend'><i class="fas fa-umbrella-beach"></i> Nature resorts near pune for weekend </Link></li> 
                 <li><Link to='Camping-Places-Near-Pune'><i class="fas fa-umbrella-beach"></i> Camping Places Near Pune </Link></li> 
                 <li><Link to='Farm-House-On-Rent-in-Pune'><i class="fas fa-umbrella-beach"></i> Farm House On Rent in Pune</Link></li> 
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className="footer-links">
                <h3 className="white">Services</h3>
                <ul>   
                <li><Link to='Corporate-Outing-Near-Mumbai'> <i class="fas fa-umbrella-beach"></i>  Corporate Outing Near Mumbai </Link></li> 
                 <li><Link to='Picnic-Spot-Near-Nashik'><i class="fas fa-umbrella-beach"></i> Picnic Spot Near Nashik  </Link></li> 
                 <li><Link to='Farm-House-On-Rent-in-Ahmednagar'><i class="fas fa-umbrella-beach"></i> Farm House On Rent in  Ahmednagar </Link></li> 
                 <li><Link to='One-Day-Picnic-Spot-Near-Pune-for-Family'> <i class="fas fa-umbrella-beach"></i> One Day Picnic Spot Near Pune for Family</Link></li> 
                 <li><Link to='One-Day-Trip-Near-Pune'> <i class="fas fa-umbrella-beach"></i> One Day Trip Near Pune</Link></li> 
                 <li><Link to='Picnic-Spot-Near-Pune'> <i class="fas fa-umbrella-beach"></i> Picnic Spot Near Pune </Link></li> 
                 <li><Link to='Corporate-Outing-Resorts-Near-Pune'> <i class="fas fa-umbrella-beach"></i> Corporate Outing Resorts Near Pune  </Link></li> 
                 <li><Link to='Destination-Wedding-Near-Pune'> <i class="fas fa-umbrella-beach"></i> Destination Wedding Near Pune </Link></li> 
                 <li><Link to='Hurda-Party-Places-Near-Pune'> <i class="fas fa-umbrella-beach"></i> Hurda Party Places Near Pune  </Link></li> 
                 <li><Link to='One-Day-Adventure-Resorts-Near-Pune'> <i class="fas fa-umbrella-beach"></i> One Day Adventure Resorts Near Pune </Link></li> 
                 <li><Link to='Forest-Resorts-Near-Pune'> <i class="fas fa-umbrella-beach"></i> Forest Resorts Near Pune  </Link></li> 
                 <li><Link to='Water-Park-Near-Pune'> <i class="fas fa-umbrella-beach"></i> Water Park Near Pune </Link></li> 
                 <li><Link to='School-Picnic-Spots-Near-Pune-for-Students'> <i class="fas fa-umbrella-beach"></i> School Picnic Spots Near Pune for Students</Link></li> 
                 <li><Link to='Accommodation-Near-Supa-in-Midc'> <i class="fas fa-umbrella-beach"></i>Accommodation Near Supa in Midc</Link></li> 
                
            
                
                </ul>
              </div>
            </div>
           
           
          </div>
        </div>
      </div>


      {/* Optional: Particles background */}
      <div id="particles-js"></div>

      <div class="phone-float">
                <a href="tel:+91 7066665554">
                <i class="bi bi-telephone-fill"></i><span>Call Us
                        <br/><small>+91 7066665554</small></span>
                </a>
            </div>

            <div class="whats-float">
                <a href="https://api.whatsapp.com/send/?phone=917066665554&text=Welcome to Glowry Stone Resort !!!!." 
                    target="_blank">
                    <span>WhatsApp<br/><small style={{ textAlign: 'right' }}>+91 7066665554</small></span><i
                        class="bi bi-whatsapp"></i>
                </a>
    
            </div>
        {/* ========================= */}
        <div class="copyright__area">
          <div class="container">
            <div class="row align-items-center">
              {/* <div className="text-white">
                All rights reserved Glowry Stone Resort 2024 Developed By
                <Link to="https://dexterintelligence.com/" target="_blank"> <span className="text fw-bold">Dexter Intelligence</span> </Link> |  <Link to='https://www.urvahosting.com/' target="_blank">Hosted By <span className="text fw-bold">Urva Hosting</span></Link> 
              </div> */}

<div className="text-white">
      All rights reserved Glowry Stone Resort <span className="text-brown fw-bold">{currentYear} | {formattedDate}</span>  Developed By 
      <Link to="https://dexterintelligence.com/" target="_blank">
        <span className="text fw-bold text-brown"> Dexter Intelligence</span>
      </Link>
      {' | '}
      <Link to="https://www.urvahosting.com/" target="_blank">
        Hosted By <span className="text fw-bold text-yellow">Urva Hosting</span>
      </Link>
    </div>
            </div>
          </div>
        </div>
    </footer>

        </>
    )
}