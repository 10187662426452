import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Activities(){
  
  const activities = [
    { name: "Outdoor Pool (Swimming Pool)", image: "glory stone images/Glowry Stone Resort Activity/Outdoor Pool  Swimming Pool.jpg" },
    { name: "Rain Dance", image: "glory stone images/Glowry Stone Resort Activity/Rain Dance.jpg" },
    { name: "Volleyball", image: "glory stone images/Glowry Stone Resort Activity/Volleyball.jpg" },
    { name: "Archery", image: "/glory stone images/Glowry Stone Resort Activity/Archery.jpg" },
    { name: "Badminton", image: "glory stone images/Glowry Stone Resort Activity/Badminton.jpg" },
    { name: "Carrom", image: "glory stone images/Glowry Stone Resort Activity/Carrom.jpg" },
    { name: "Ludo", image: "glory stone images/Glowry Stone Resort Activity/Ludo.jpg" },
    { name: "Musical Chair Game", image: "glory stone images/Glowry Stone Resort Activity/Musical Chair Game.jpg" },
    { name: "Children Park to Play", image: "glory stone images/Glowry Stone Resort Activity/Children Park to Play.jpg" },
    { name: "Rope Pulling Game (Tug of War)", image: "glory stone images/Glowry Stone Resort Activity/Rope Pulling Game.jpg" },
    { name: "Farming", image: "glory stone images/Glowry Stone Resort Activity/Farming.jpg" },
    { name: "Rides (Tractor, Bullcart)", image: "glory stone images/Glowry Stone Resort Activity/Rides.jpg" },
    { name: "Zip Line", image: "glory stone images/Glowry Stone Resort Activity/Zip Line.jpg" },
    { name: "Trekking", image: "glory stone images/Glowry Stone Resort Activity/Trekking.jpg" },
    { name: "Cricket", image: "glory stone images/Glowry Stone Resort Activity/Cricket.jpg" },
    { name: "Musical Dance", image: "glory stone images/Glowry Stone Resort Activity/dance.jpg" },
    { name: "Campfire", image: "glory stone images/Glowry Stone Resort Activity/Campfire.jpg" },
    { name: "Bird Watching", image: "glory stone images/Glowry Stone Resort Activity/Bird Watching.jpg" },
    { name: "Tarzan Rope", image: "glory stone images/Glowry Stone Resort Activity/Tarzanrope.jpg" },
    { name: "Spider Net / Commando Net", image:"glory stone images/Glowry Stone Resort Activity/Spider Net  commando net.jpg" },
  ];
  
  
  const offers = [
   
    {
      id: 2,
      image: 'glory stone images/Glowry Stone Resort Activity/Bird Watching.jpg',
      title: 'Nature Walks and Bird Watching for Nature Lovers',
      days: 'Glowry Stone Resort',
      para:'Immerse yourself in the natural beauty of the resort with peaceful nature walks. As you stroll through verdant landscapes, youll have the opportunity to spot a variety of birds and wildlife. Ideal for nature enthusiasts, this activity offers a chance to reconnect with the environment and appreciate its serene beauty.'
    },
    {
      id: 2,
      image: 'glory stone images/Glowry Stone Resort Activity/Zip Line.jpg',
      title: 'Zip-lining and Rock Climbing for Adventure Seekers',
      days: 'Glowry Stone Resort',
      para:'If youre seeking an adrenaline rush, the resort’s zip-lining and rock climbing activities will give you just that! Soar across the landscape on a thrilling zip-line or challenge yourself with a rock climbing session, both offering unique ways to experience the resort’s stunning natural surroundings from new heights.'
    },

    {
      id: 2,
      image: 'glory stone images/Glowry Stone Resort Activity/Campfire.jpg',
      title: 'Bonfire Nights with BBQ and Music for Cozy Evenings',
      days: 'Glowry Stone Resort',
      para:'End your day with a cozy bonfire under the stars. Gather around with friends or family, indulge in a delicious BBQ, and enjoy music or storytelling by the fire. The bonfire nights at Glowry Stone Resort are a memorable experience that brings people together and creates lasting memories.'
    },
 
  ];

  
const articles = [
    {
      id: 1,
      category: 'Glowry Stone Resort',
      title: 'Scenic Nature Trails',
      description: 'Discover the breathtaking beauty of our nature trails. Explore lush forests, rolling hills, and panoramic views while enjoying the serenity of the outdoors',
      image: 'images/trending/trending10.jpg',
    },
    {
      id: 1,
      category: 'Glowry Stone Resort',
      title: 'Bonfire Nights with BBQ',
      description: 'Gather around a cozy bonfire in the evening and enjoy a BBQ with your loved ones while the stars light up the night sky.',
      image: 'images/trending/trending10.jpg',
    },
    {
      id: 1,
      category: 'Glowry Stone Resort',
      title: 'Thrilling Adventure Activities',
      description: 'For the adventure seekers, enjoy activities like rock climbing, zip-lining, and ATV rides that will surely get your adrenaline pumping!',
      image: 'images/trending/trending10.jpg',
    },
  ];

    return(
        <>
        <Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - Activities| One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune | Camping Places Near Pune
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Home | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.jpg",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>
         <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3">Activities</h1>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Activities
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>

    <section className="trending pb-0 pt-4">
      <div className="container">
        <div className="section-title mb-6 w-75 mx-auto text-center">
          <h4 className="mb-1 theme1">We Provide our customers..</h4>
          <h2 className="mb-1">
            <span className="theme">Best Activities</span> 
          </h2>
          <p>
          Glowry Stone Resort is a luxurious, serene retreat nestled in nature's embrace, offering an enchanting blend of modern comfort and tranquil beauty. 
          </p>
        </div>
        <div className="trend-box">
          <div className="row">
            {offers.map((offer) => (
              <div key={offer.id} className="col-lg-4 col-md-6 col-sm-6 mb-4 ">
                <div className="trend-item rounded activity-box  bg-white">
                  <div className="trend-image position-relative">
                    <img src={offer.image} alt="image" />
                    <div className="ribbon ribbon-top-left">
                      <span className="fw-bold">{offer.discount}</span>
                    </div>
                    <div className="color-overlay"></div>
                  </div>
                  <div className="trend-content p-4 pt-5 position-relative">
                    <div className="trend-meta bg-theme white px-3 py-2 rounded">
                      <div className="entry-author">
                        <i className="icon-calendar"></i>
                        <span className="fw-bold"> {offer.days}</span>
                      </div>
                    </div>
                    <h5 className="theme mb-1">
                      <i className="flaticon-location-pin"></i> {offer.country}
                    </h5>
                    <h3 className="mb-1">
                      <a href="">{offer.title}</a>
                    </h3>
              
                    <p className="border-b pb-2 mb-2">
                     {offer.para}
                    </p>
                    <div className="entry-meta">
                      <div className="entry-author d-flex align-items-center">
                        <p className="mb-0 text-center">
                        <Link to='/contact' class="">Book Now <i className="fa fa-arrow-right"></i></Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>

    <section className="activities-section">
      <div className="container">
        <div className="activities-grid">
          {activities.map((activity, index) => (
            <div key={index} className="activity-card">
              <img
                src={activity.image}
                alt={activity.name}
                className="activity-image"
              />
              <h3 className="activity-name">{activity.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>


        </>
    )
}