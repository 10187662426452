import Slider from "react-slick";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import './Page.css';
import GallerySlider from "./GallerySlider";
import Contactbox from "./Contactbox";
import RecentPost from "./RecentPost";
import Contact from "./Contact";
import ContactSection from "./ContactSection";
import { Helmet } from 'react-helmet';


export default function OnedayAdventuresResortNearAhmednagar(){

    const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [

        {
          "question": "What adventure activities are available at Glowry Stone Resort?",
          "answer": "At Glowry Stone Resort, we offer a wide range of adventure activities including rock climbing, zip-lining, trekking, nature walks, camping, archery, outdoor sports, and team-building games. We ensure that everyone from beginners to experienced adventurers can enjoy an exciting day."
        },
        {
          "question": "How far is Glowry Stone Resort from Ahmednagar?",
          "answer": "Glowry Stone Resort is conveniently located just a short drive away from Ahmednagar, making it an easily accessible destination for a one-day adventure outing. The resort is approximately a 1-hour drive from the city center, depending on traffic conditions."
        },
        {
          "question": "Is the resort family-friendly?",
          "answer": "Yes, Glowry Stone Resort is perfect for families! We offer a variety of adventure activities that are suitable for all age groups, including children and adults. From safe and fun activities for kids to challenging adventures for adults, it’s a great spot for family bonding."
        },
        {
          "question": "Do you offer team-building activities for corporate outings?",
          "answer": "Yes! We specialize in corporate team-building activities and offer a variety of outdoor challenges designed to improve communication, collaboration, and problem-solving among team members. Corporate groups can enjoy a full day of adventure, team games, and group activities."
        },
        {
          "question": "Can I book the resort for a day outing?",
          "answer": "Absolutely! Glowry Stone Resort offers a range of day outing packages for groups, families, and corporate teams. Whether you're looking for a day of adventure, relaxation, or a combination of both, our one-day packages can be customized to suit your needs."
        },
        {
          "question": "What is the cost of a one-day adventure outing at the resort?",
          "answer": "Our pricing for a one-day adventure outing varies depending on the activities you choose and the size of your group. We offer affordable and customized packages that are designed to give you the best value for your money. Please contact us for specific pricing and package details."
        },
        {
          "question": "Are there food options available at the resort?",
          "answer": "Yes, Glowry Stone Resort provides a variety of food options, including breakfast, lunch, and snacks. We offer both vegetarian and non-vegetarian meals, prepared fresh on-site. You can also opt for customized meal plans depending on your group’s needs."
        },
        {
          "question": "Is Glowry Stone Resort a safe place for adventure activities?",
          "answer": "Absolutely! Safety is our top priority at Glowry Stone Resort. All our adventure activities are supervised by trained professionals, and we follow strict safety protocols to ensure that participants are safe throughout their experience. Equipment is regularly checked and maintained for the safety of our guests."
        },
        {
          "question": "Can I book a one-day adventure package online?",
          "answer": "Yes, you can easily book a one-day adventure outing at Glowry Stone Resort through our website. Simply choose your preferred date and package, and make the reservation. Our team will confirm your booking and provide you with all the necessary details."
        },
        {
          "question": "Is the resort suitable for large group outings?",
          "answer": "Yes, Glowry Stone Resort is well-equipped to accommodate large groups. Whether you're planning a corporate outing, a family reunion, or a gathering of friends, our resort offers ample space and facilities for group activities. We can also customize your adventure experience to suit the size and preferences of your group."
        }
    
      
  ];
  
      const testimonials = [
            {
              "quote": "We recently visited Glowry Stone Resort for a corporate team outing, and it was nothing short of amazing! The resort offers a perfect blend of adventure, nature, and relaxation. From rock climbing to zip-lining, everyone in our group had a blast. The team-building activities were well-organized and engaging, making it an excellent choice for corporate events. The staff were incredibly friendly, and the food was delicious. It was a day packed with fun and laughter, and we will definitely be back for more!",
              "author": "— Ananya R., Corporate Event Organizer"
            },
            {
              "quote": "Glowry Stone Resort provided our family with an unforgettable one-day adventure outing near Ahmednagar. The variety of activities, including trekking and archery, was perfect for all age groups. Our kids had so much fun on the nature trails, while we enjoyed the challenging outdoor games. The resort's peaceful setting and the well-maintained facilities made it a great escape from the city. A big thank you to the team for making our day so special! We’ll definitely recommend this resort for anyone looking for adventure and relaxation combined!",
              "author": "— Rahul & Priya M., Family Vacationers"
            }
          
        ];
  
        
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
  
    const hotelImages = [
      {
        place: "Hotel-Images/Hotel In Viman Nagar.jpg",
        text: "Hotel In Viman Nagar",
        path:'hotel-in-viman-nagar'
      },
      {
        place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
        text: "Hotels in Viman Nagar for Stay",
        path:'viman-nagar-hotels-for-stay'
      },
      {
        place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
        text: "Lodge in Viman Nagar Pune",
        path:'lodge-in-viman-nagar-pune'
      },
      {
        place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
        text: "Hotels Near Viman Nagar Airport Pune",
        path:'hotels-near-viman-nagar-airport-pune'
      },
      {
        place: "Hotel-Images/Pune Airport Hotel.jpg",
        text: "Pune Airport Hotel",
        path:'pune-airport-hotel'
      },
      {
        place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
        text: "Hotel in Wadgaon Sheri",
        path:'hotel-wadgaon-sheri'
      },
      {
        place: "Hotel-Images/Online hotel booking in pune.jpg",
        text: "Online Hotel Booking in Pune",
        path:'online-hotel-booking-pune'
      },
      {
        place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
        text: "Hotel For Stay in Lohegaon Airport",
        path:'hotel-for-stay-lohegaon-airport'
      }
  
    
    ];


    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TouristAttraction",
      "name": "Glowry Stone Resort - Adventure Resorts Near Ahmednagar",
      "image": "https://www.glowrystone.com/images/adventure-resort.jpg", // Image URL for adventure resort
      "description": "Glowry Stone Resort near Ahmednagar offers one-day adventure resorts with exciting activities for family outings, corporate team-building, and day trips. Enjoy outdoor adventure and fun activities.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road",
        "addressLocality": "Parner",
        "addressRegion": "Maharashtra",
        "postalCode": "414302",
        "addressCountry": "IN"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 7066665554",
        "contactType": "Customer Service",
        "areaServed": "IN",
        "availableLanguage": "English"
      },
      "sameAs": [
        "https://www.facebook.com/glowrystone",
        "https://www.instagram.com/glowrystone"
      ],
      "url": "https://www.glowrystone.com"
    };
  
    
  
    return(
        <>

<Helmet>
        {/* Title */}
        <title>
          Contact: +91 7066665554 / +91 9145557771 | One Day Adventure Resorts Near Ahmednagar | Adventure Resorts for Day Out, Family, & Corporate Outings
        </title>

        {/* Meta Tags */}
        <meta
          name="description"
          content="Glowry Stone Resort near Ahmednagar offers exciting one-day adventure resorts for family outings, corporate team-building activities, and day trips with outdoor adventure fun."
        />
        <meta
          name="keywords"
          content="one day adventure resorts near Ahmednagar, adventure resorts near Ahmednagar, day outing resorts near Ahmednagar, best adventure resorts near Ahmednagar, adventure park in Ahmednagar for family, one day resorts with activities near Ahmednagar, Om Agro Tourism Adventure Resort, family adventure resorts Ahmednagar, adventure outing resorts near Ahmednagar"
        />
        <meta name="author" content="Glowry Stone Resort" />
        <meta name="robots" content="index, follow" />

        {/* Open Graph for Social Sharing */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Glowry Stone Resort - Adventure Resorts Near Ahmednagar for One Day Trip & Family Fun"
        />
        <meta
          property="og:description"
          content="Enjoy a thrilling one-day adventure resort experience near Ahmednagar. Perfect for family day outings, corporate team-building, and group adventures with exciting outdoor activities."
        />
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" />
        <meta property="og:site_name" content="Glowry Stone Resort" />

        {/* Contact Information */}
        <meta
          name="contact"
          content="For reservations and inquiries about adventure resorts, please contact Glowry Stone Resort at +91 7066665554 or +91 9145557771. Email us at reservation@glowrystone.com."
        />
        <meta
          name="address"
          content="Glowry Stone Resort, Wadegavhan, Ahmednagar-Nagar Road, Tal. Parner, Dist. Ahilya Nagar (Ahmednagar), Maharashtra - 414302, India"
        />
        <meta name="email" content="reservation@glowrystone.com" />
        <meta name="phone" content="+91 7066665554, +91 9145557771" />

        {/* Canonical Link */}
        <link rel="canonical" href="https://www.glowrystone.com" />

        {/* JSON-LD Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>

    <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3 text-white"> One Day Adventure Resorts Near Ahmednagar</h2>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


    {/* ========================= */}

    <section className="trending recent-articles pb-6 keywordsection">
      <div className="container">
      <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="glory stone images/Glowry Stone Resort keyword/One day adventure resort near ahmednagar.jpg" className="img-fluid" alt="One day adventure resort near ahmednagar" />
              </div>
              <h2>Glowry Stone Resort: The Ultimate One-Day Adventure Resort Near Ahmednagar</h2>

<p>Look no further than Glowry Stone Resort, a one-day adventure resort offering a perfect blend of thrilling activities, nature, and relaxation. Whether you're planning a family getaway, a team outing, or simply a day of adventure with friends, Glowry Stone Resort is the ideal destination to escape the mundane and enjoy a day full of excitement.</p>

<h3>One Day Adventure Resorts Near Ahmednagar – Glowry Stone Resort</h3>

<p>If you're in search of an exciting day trip packed with adventure near Ahmednagar, Glowry Stone Resort is the perfect destination. Our resort offers an immersive experience for those seeking thrilling outdoor activities, relaxation, and scenic beauty all in one place. Located just a short drive from Ahmednagar, it’s ideal for a quick getaway or day out.</p>

<h3>Adventure Resort Ahmednagar Day Out</h3>

<p>At Glowry Stone Resort, your adventure day out near Ahmednagar will be filled with exciting activities that cater to all levels of adventure seekers. Whether you're an adrenaline junkie or someone looking for a fun escape, our resort offers a wide range of adventure activities to keep you entertained throughout the day. These activities include:</p>

<ul>
  <li>Rock Climbing</li>
  <li>Zip-lining</li>
  <li>Trekking</li>
  <li>Team-building Games</li>
  <li>Nature Walks</li>
</ul>

<h3>One Day Adventure Resort Near Ahmednagar</h3>

<p>Perfect for families, groups, and corporate teams, Glowry Stone Resort provides the best one-day adventure resort near Ahmednagar. Whether you want a day full of adventure or a mix of relaxation and outdoor fun, our resort has it all. The property is surrounded by nature, providing a peaceful environment for both high-energy activities and moments of relaxation.</p>

<h3>One Day Adventure Resorts Near Ahmednagar</h3>

<p>Looking for a fun-filled, action-packed day trip near Ahmednagar? Glowry Stone Resort offers the best one-day adventure resorts near Ahmednagar. Our adventure day packages include exciting activities that can be enjoyed by guests of all ages and group sizes. From outdoor sports and challenges to relaxing by the water or taking in the natural beauty of the area, it’s the perfect place to escape the monotony of daily life.</p>

<h3>1 Day Adventure Resort Near Ahmednagar</h3>

<p>Glowry Stone Resort is the perfect one-day adventure resort near Ahmednagar for those who want to enjoy a quick escape without the hassle of long-distance travel. The resort is designed to give you a complete adventure experience in just one day, with a combination of thrilling activities and a peaceful environment for relaxation. Whether you’re looking for an adventurous team outing or a family bonding experience, we offer a wide variety of activities to suit every need.</p>

<h3>Nature Trails & Trekking</h3>

<p>For nature lovers and those looking to explore the beauty of the outdoors, Glowry Stone Resort offers nature trails and trekking routes that will take you through lush greenery and breathtaking landscapes. This is the perfect way to immerse yourself in nature while getting some exercise.</p>

<h3>Rock Climbing & Zip-Lining</h3>

<p>For adventure enthusiasts, we have rock climbing and zip-lining setups that give you a high-energy, exhilarating experience. These activities are sure to get your heart pumping and are perfect for corporate team-building or a fun day out with friends and family.</p>



<h2>Relaxing Outdoor Environment</h2>

<p>While the adventure activities keep you on your toes, the tranquil surroundings of Glowry Stone Resort provide plenty of opportunities to unwind. Relax in our peaceful spaces or have a picnic near the beautiful natural landscapes.</p>

<h3>Team Building Activities</h3>

<p>For corporate teams looking for a day out, our team-building activities encourage collaboration, communication, and fun challenges. These activities are tailored to strengthen team dynamics while offering an adventurous day outdoors.</p>

<h3>Perfect for All Groups</h3>

<p>Whether you are planning a family outing, a team-building day, or a fun gathering with friends, Glowry Stone Resort is perfect for all types of groups. Our one-day adventure resort near Ahmednagar is designed to accommodate small and large groups, ensuring everyone has a fantastic time.</p>

<h3>Book Your Day of Adventure</h3>

<p>Don’t miss out on an unforgettable experience! Book your day at Glowry Stone Resort today, and enjoy a one-day adventure packed with exciting activities, stunning views, and the perfect blend of relaxation and thrill. Whether it’s a corporate outing, a family get-together, or just a fun day with friends, we guarantee you’ll have the time of your life at Glowry Stone Resort.</p>

<h3>Best Adventure Resorts Near Ahmednagar for Day Outing</h3>

<p>At Glowry Stone Resort, we pride ourselves on offering the best adventure activities for all types of guests. From heart-pumping rock climbing and zip-lining to fun team-building activities, our resort is a perfect day destination for adventure seekers. Located near Ahmednagar, Glowry Stone Resort provides an exciting mix of adventure and relaxation for individuals, families, and corporate groups.</p>

<h3>Adventure Resorts Near Ahmednagar for Corporate Outing</h3>

<p>Looking for the perfect corporate outing resort near Ahmednagar? Glowry Stone Resort provides an ideal blend of adventure and relaxation for corporate teams. We specialize in corporate team-building activities, including outdoor games, group challenges, and leadership-building exercises, designed to improve collaboration and communication. Our wide range of outdoor activities ensures that your team will have a fantastic time, whether you're looking for some fun competition or simply relaxing and bonding in nature.</p>

<h3>Resorts Near Ahmednagar for Family One Day Trip</h3>

<p>For families looking for a one-day adventure resort near Ahmednagar, Glowry Stone Resort is an ideal getaway. Our resort offers activities suitable for all ages, including nature trails, picnic spots, and interactive games for kids and adults alike. Whether you're looking to trek through the scenic landscape, play outdoor sports, or simply relax amidst nature, Glowry Stone Resort has something for everyone. It’s the perfect place for families to bond, unwind, and make lasting memories.</p>

<h3>One Day Picnic Resorts with Activities Near Ahmednagar</h3>

<p>Looking for a one-day picnic resort with activities near Ahmednagar? Glowry Stone Resort offers the perfect balance of adventure and relaxation. With our wide variety of outdoor activities like cycling, camping, rock climbing, and nature walks, you and your group will have an unforgettable day. Whether you're visiting with family, friends, or colleagues, there's no shortage of fun things to do at our resort.</p>

<h3>Adventure Park in Ahmednagar for Family</h3>

<p>For families searching for an adventure park in Ahmednagar, Glowry Stone Resort provides the perfect environment. Our resort has dedicated areas for adventure sports such as zip-lining, rock climbing, and trekking, allowing families to enjoy an active, fun-filled day together. The natural beauty of our surroundings adds an extra layer of serenity, making it the perfect place to relax after an action-packed day.</p>

<h3>Best Adventure Resort in Ahmednagar</h3>

<p>Glowry Stone Resort is often regarded as the best adventure resort in Ahmednagar due to its combination of exciting activities, beautiful natural surroundings, and exceptional hospitality. Whether you're coming for a quick getaway, a team-building outing, or a family picnic, our resort offers everything you need to create an unforgettable experience. Our guests love the range of adventure activities and the tranquil environment that makes us stand out as a premier resort in the Ahmednagar area.</p>

<h3>Adventure Resorts Near Ahmednagar for Family</h3>

<p>Families looking for a family-friendly adventure resort near Ahmednagar will find Glowry Stone Resort to be an ideal choice. Our resort offers activities that appeal to all ages, ensuring that everyone in the family will find something to enjoy. From fun adventure games for the kids to relaxing nature walks for the adults, Glowry Stone Resort is designed for families who want to spend quality time together while enjoying the outdoors.</p>

<h3>One Day Picnic Resorts with Activities Near Ahmednagar</h3>

<p>Glowry Stone Resort is perfect for those seeking one-day picnic resorts with activities near Ahmednagar. We offer a wide variety of engaging activities, including team-building games, rock climbing, and nature walks, ensuring that your picnic experience is both relaxing and action-packed. Whether you're looking to explore the natural beauty of the area, enjoy some friendly competition, or just relax by the scenic views, Glowry Stone Resort provides the perfect setting.</p>

<h3>Glowry Stone Resort in Pune Contact Number</h3>

<p>Contact Glowry Stone Resort at [+91 7066665554] for our Glowry Stone Resort is not just a picnic spot near Ahmednagar; it’s an experience that brings families closer to nature, provides them with endless opportunities to bond, and ensures a day of relaxation, fun, and enjoyment.</p>


              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- One day adventure resorts near Ahmednagar</p>
<p>- Adventure resort Ahmednagar day out</p>
<p>- One Day Adventure Resort Near Ahmednagar</p>
<p>- One day adventure resorts near Ahmednagar</p>
<p>- 1 Day Adventure Resort Near Ahmednagar</p>
<p>- Best adventure resorts near Ahmednagar for day outing</p>
<p>- Adventure resorts near Ahmednagar for corporate outing</p>

                    </td>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- Resorts near Ahmednagar for family one day trip</p>
<p>- One day picnic resorts with activities near Ahmednagar</p>
<p>- Adventure park in Ahmednagar for family</p>
<p>- Best adventure resort in Ahmednagar</p>
<p>- Adventure resorts near Ahmednagar for family</p>
<p>- One day picnic resorts with activities near Ahmednagar</p>

                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>Why Choose Glowry Stone Resort for One Day Adventure Resorts Near Ahmednagar?</h2>

<p>Glowry Stone Resort is the ultimate destination for an action-packed and refreshing one-day adventure getaway near Ahmednagar. Here's why you should choose us for your next adventure outing:</p>

<ul>
  <li>
    <h3>1. Variety of Adventure Activities</h3>
    <p>At Glowry Stone Resort, we offer a wide range of adventure activities that cater to all kinds of adventurers. From rock climbing, zip-lining, and trekking to nature walks, outdoor sports, and team-building challenges, we ensure there’s never a dull moment. Whether you’re visiting with friends, family, or colleagues, you’ll find activities that will excite everyone.</p>
  </li>
  
  <li>
    <h3>2. Scenic and Refreshing Environment</h3>
    <p>Located in the lush, tranquil surroundings of Ahmednagar, Glowry Stone Resort offers a serene and beautiful escape from the hustle and bustle of city life. Our location amidst nature enhances the adventure experience, with scenic landscapes perfect for unwinding after an action-packed day. The peaceful setting provides the ideal backdrop for relaxation and adventure in one.</p>
  </li>
  
  <li>
    <h3>3. Ideal for Corporate Outings and Team Building</h3>
    <p>Glowry Stone Resort is an excellent choice for corporate outings and team-building activities. We provide tailored adventure programs designed to foster team spirit, improve collaboration, and boost morale. Whether you're planning a day of competitive outdoor activities or team challenges, our resort offers the perfect setting to improve teamwork while enjoying the outdoors.</p>
  </li>
  
  <li>
    <h3>4. Family-Friendly Adventure Experience</h3>
    <p>Our resort is perfect for families looking for a fun and safe adventure day out. With a range of activities suitable for all age groups, from kids to adults, Glowry Stone Resort is a great spot for a family outing. Let your children explore and enjoy adventure games while the adults relax in nature, making it an experience the whole family can enjoy together.</p>
  </li>
  
  <li>
    <h3>5. Affordable Day Outing Packages</h3>
    <p>We understand the importance of value for money. Our affordable day outing packages are designed to fit a variety of budgets, making it possible for anyone to experience an exciting adventure at Glowry Stone Resort. Whether you’re organizing a group outing, a family picnic, or a corporate retreat, we offer competitive pricing without compromising on the quality of your experience.</p>
  </li>
  
  <li>
    <h3>6. Eco-Friendly Resort with Sustainable Practices</h3>
    <p>At Glowry Stone Resort, we are committed to sustainability. Our eco-friendly practices help preserve the natural beauty of the area, ensuring that your adventure experience is not only thrilling but also environmentally responsible. Our eco-tourism initiatives include waste management, water conservation, and promoting the local ecosystem, so you can enjoy the great outdoors without harming the environment.</p>
  </li>
  
  <li>
    <h3>7. Customization for Your Group</h3>
    <p>We understand that every group has unique needs, whether it’s a corporate outing, a family trip, or a school group. Glowry Stone Resort offers customizable packages to suit your specific requirements. From specialized adventure activities to food arrangements, we work closely with you to ensure a personalized and unforgettable experience.</p>
  </li>
  
  <li>
    <h3>8. Easy Accessibility from Ahmednagar</h3>
    <p>Located just a short drive from Ahmednagar, Glowry Stone Resort is easily accessible for a quick day trip. You don’t have to travel far to escape into nature and enjoy a thrilling adventure. Whether you’re coming from Ahmednagar or nearby cities, our resort is a convenient and accessible destination for a one-day adventure escape.</p>
  </li>
  
  <li>
    <h3>9. Professional and Friendly Staff</h3>
    <p>Our professional and friendly staff are committed to providing excellent service and ensuring that your adventure outing is smooth and enjoyable. From helping with activity bookings to offering assistance during your stay, our team is always ready to help, ensuring that you have a hassle-free and memorable experience.</p>
  </li>
  
  <li>
    <h3>10. Perfect for All Group Sizes</h3>
    <p>Whether you are planning a small outing with friends, a large corporate event, or a family reunion, Glowry Stone Resort is designed to accommodate groups of all sizes. With spacious facilities and ample space for activities, we can comfortably host groups of any size, making us the ideal location for your adventure day out.</p>
  </li>
</ul>

              <div className="">
                <h2>
                FAQs for Glowry Stone Resort – One Day Adventure Resorts Near Ahmednagar?
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

                {/* ======================== */}
                <div className="">
                  <h2>
                  Testimonials for "Glowry Stone Resort" for One Day Adventure Resorts Near Ahmednagar:- {" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>
                  
                <ContactSection></ContactSection>

</div>
<div class="col-12 col-md-4">
<RecentPost></RecentPost>
  <Contactbox></Contactbox>
</div> 
</div>
            
      </div>
    </section>

  <GallerySlider></GallerySlider>

        </>
    )
}  