// Import necessary libraries
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";


const testimonials = [
  { 
    para:"An Unforgettable Experience!",
    text: "We had the most amazing family getaway at Glowry Stone Resort! From the stunning natural beauty to the range of activities available, everything was perfect. The kids loved the nature walks and bird watching, while the adults enjoyed peaceful evenings by the lake. The staff was incredibly friendly and made us feel right at home. We'll definitely be back for another relaxing retreat!",
    name: "– Priya & Family, Pune",
    imgSrc: "glory stone images/testimonial/testL1.png"
  },
  {
    para:"A Perfect Corporate Retreat",
    text: "Our team had an incredible experience at Glowry Stone Resort during our corporate retreat. The resort provided the ideal environment for both team-building activities and relaxation. The outdoor games, trekking trails, and bonfire nights were a hit with everyone. The staff went above and beyond to accommodate our needs, making it a productive and enjoyable getaway. Highly recommended!",
    name:'–Mr.Ravi S',
    imgSrc: "glory stone images/testimonial/testG1.png"
  },
  {
    para: "Romantic and Serene",
    text: "My partner and I celebrated our anniversary at Glowry Stone Resort, and it was everything we hoped for and more. The lakeside setting was beautiful, and the tranquility of the resort made it the perfect romantic getaway. We enjoyed a peaceful boat ride, a candlelit dinner by the lake, and relaxing nature walks. The resort's attention to detail and warm hospitality made it an unforgettable experience. Highly recommend for couples!",
    name: "– Anjali & Arvind, Mumbai",
    imgSrc: "glory stone images/testimonial/testL2.png"
  },
  {
    para:  "Adventure and Relaxation Combined!",
    text: "If you're an adventure enthusiast, Glowry Stone Resort is the place to be! From zip-lining and trekking to ATV rides, there's no shortage of thrilling activities. At the same time, the resort offers plenty of peaceful spots to unwind. I loved every minute of my stay here and will definitely return for more outdoor adventures. A perfect blend of excitement and relaxation!",
    name: "– Kunal D., Pune",
    imgSrc: "glory stone images/testimonial/testG3.png"
  },
  {
    para:  "A Slice of Heaven!",
    text: "Glowry Stone Resort is an absolute hidden gem! The moment we arrived, we were blown away by the beautiful surroundings. The resort is well-maintained, and the rooms are spacious and comfortable. We spent our days exploring the picturesque trekking trails and enjoying bonfire nights. The food was delicious, and the staff made us feel like VIPs. We left feeling completely rejuvenated and can't wait to visit again!",
    name: "– Sonal & Friends, Mumbai",
    imgSrc: "glory stone images/testimonial/testL3.png"
  },
  {
    para:   "Perfect Weekend Getaway",
    text: "Glowry Stone Resort was the perfect weekend escape from the chaos of the city. The location is peaceful and offers stunning views, making it an ideal place for relaxation. We loved the nature walks and the delicious BBQ dinner by the bonfire. The resort offers everything you need to unwind and recharge. It was a truly refreshing experience, and I can't recommend it enough!",
    name: "– Vishal & Neha, Pune",
    imgSrc: "glory stone images/testimonial/testG5.png"
  }
];

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true
  };

  return (
    <>
     <Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - Attraction | One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune | Camping Places Near Pune
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Attraction | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.png",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>
  
    <section className="testimonial pt-4">
      <div className="container">
        <div className="section-title mb-6 text-center w-75 mx-auto">
          <h4 className="mb-1 theme1">Our Testimonials</h4>
          <h2 className="mb-1">Good Reviews By <span className="theme">Customers</span></h2>

        </div>
        <div className="testimonial-in">
          <Slider {...settings} className="review-slider1">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="item">
                <div className="testimonial-item1 rounded bg-white p-4 py-5 box-shadow  text-center mx-2">
                  <div className="details mb-4">
                    <p className="m-0">
                      <i className="fa fa-quote-left me-2 fs-1"></i>
                     <span className="fw-bold"> {testimonial.para}</span><br/>
                      {testimonial.text}
                    </p>
                  </div>
                  <div className="author-info mt-2">
                    <a href="#"><img src={testimonial.imgSrc} alt={testimonial.name} /></a>
                    <div className="author-title ms-3">
                      <h5 className="m-0 theme">{testimonial.name}</h5>
                      <span>{testimonial.title}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
    </>
  );
};

export default Testimonial;
