import React, { useState,useEffect } from "react";
import { useRef } from 'react';
import { Link } from 'react-router-dom';  
import { Helmet } from "react-helmet";
export default function Card() {
 
    const hotelCards = [
        {
          link:'best-hotels-in-pune-for-couples',
          image: "glory stone images/Glowry Stone Resort/Glowry Stone Resort bedroom.jpg",
        },
        {
          link:'corporate-hotel-booking-in-viman-nagar',
          image: "glory stone images/Glowry Stone Resort/Glowry Stone Resort dish.jpg",
        },
        {
          link:'hotel-for-stay-lohegaon-airport',
         
          image: "glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery bedroom view.jpg",
        },
    
        {
          link:'business-stays-in-viman-nagar',
          image: "glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery3.jpg",
        },

        
        {
         
          image: "glory stone images/Glowry Stone Resort/Glowry stone Resort gallery1.jpg",
        },
        {
         
          image: "glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery6.jpg",
        },
    
      
      ];


      const containerRef = useRef(null);

      useEffect(() => {
        const scrollContainer = containerRef.current;
        let scrollAmount = 0;
        const scrollSpeed = 1; // Adjust scroll speed
    
        const scroll = () => {
          if (scrollContainer) {
            scrollAmount += scrollSpeed;
            scrollContainer.scrollLeft += scrollSpeed; // Scroll by the scroll speed
    
            // Reset scroll when it reaches the end
            if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
              scrollContainer.scrollLeft = 0; // Reset to the start
            }
          }
    
          requestAnimationFrame(scroll);
        };
    
        scroll();
    
        return () => {
          cancelAnimationFrame(scroll);
        };
      }, []);
    
      
  return (
    
    <>
    <Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - Home| One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune | Camping Places Near Pune
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Home | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.png",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>
      <div className="hotel-section">
      <div className="hotel-cards-container" ref={containerRef}>
        {/* Original cards */}
        {hotelCards.map((hotel, index) => (
          <div className="hotel-card" key={index}>
            <img src={hotel.image} alt={hotel.description} className="hotel-image" />
            <Link to={hotel.link}><p>{hotel.description}</p></Link>
          </div>
        ))}

        {/* Duplicated cards to create the infinite loop */}
        {hotelCards.map((hotel, index) => (
          <div className="hotel-card" key={`duplicate-${index}`}>
            <img src={hotel.image} alt={hotel.description} className="hotel-image" />
            <Link to={hotel.link}><p>{hotel.description}</p></Link>
          </div>
        ))}
      </div>
    </div>
    </>
  )
}
