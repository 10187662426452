import Slider from 'react-slick';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";


export default function NearPlaces(){



    return(
        <>
        <Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - Near Places| Forest Resorts Near Ahmednagar
          | Nature resorts near Ahmednagar for weekend | Mango Mahotsav in Ahmednagar
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Home | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune | Forest Resorts Near Ahmednagar
        | Nature resorts near Ahmednagar for weekend | Mango Mahotsav in Ahmednagar" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.png",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>
          <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3">Nearby Places</h1>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Nearby Places
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>



    <section id="nearby-places" className="nearby-places-section">
      <div className="container">
        {/* Section Header */}
        <div className="section-header text-center">
          <h2 className="section-title">Explore Nearby Places</h2>
          <p className="section-subtitle">
            Discover the rich heritage, scenic beauty, and spiritual landmarks around Glowy Stone Resort. From nature escapes to historical sites, enjoy the best of Ahmednagar and beyond.
          </p>
        </div>

        {/* Attractions Grid */}
        <div className="row attractions-grid">
          {/* Attraction 1: Village Ralegan Siddhi */}
          <div className="col-md-4">
            <div className="attraction-card">
              <div className="attraction-img" >
                <img src="glory stone images/Glowry Stone Resort/village siddhi.jpg" alt=""/>
              </div>
              <div className="attraction-info">
                <h4 className="attraction-title">Village Ralegan Siddhi</h4>
                <p className="attraction-description">
                  Known for its sustainable rural development led by Anna Hazare, this village offers a unique model of eco-friendly practices. Visit to learn about water conservation and rural self-reliance.
                </p>
              </div>
            </div>
          </div>

          {/* Attraction 2: Mahaganpati Ranjangaon */}
          <div className="col-md-4">
            <div className="attraction-card">
              <div className="attraction-img bg1" >
                <img src="glory stone images/Glowry Stone Resort\Mahaganpati.jpg" alt=""/>
              </div>
              <div className="attraction-info">
                <h4 className="attraction-title">Mahaganpati Ranjangaon</h4>
                <p className="attraction-description">
                  A revered pilgrimage site dedicated to Lord Ganesha, this Ashtavinayak temple offers peace, serenity, and divine energy. A must-visit for spiritual seekers.
                </p>
              </div>
            </div>
          </div>

          {/* Attraction 3: Nighoj Kund */}
          <div className="col-md-4">
            <div className="attraction-card">
              <div className="attraction-img nighojkund" >
                <img src="glory stone images/Glowry Stone Resort/nighoj kund.jpg" alt=""/>
              </div>
              <div className="attraction-info">
                <h4 className="attraction-title">Nighoj Kund</h4>
                <p className="attraction-description">
                  Explore the natural wonder of Nighoj Kund, a geological marvel formed by river erosion, creating beautiful potholes in the rocks. A perfect spot for nature lovers and photographers.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* More Attractions */}
        <div className="row attractions-grid mt-5">
          {/* Attraction 4: Morachi Chincholi */}
          <div className="col-md-4">
            <div className="attraction-card">
              <div className="attraction-img" >
               <img src="glory stone images/Glowry Stone Resort/morachi chincholi.jpg" alt=""/>
              </div>
              <div className="attraction-info">
                <h4 className="attraction-title">Morachi Chincholi</h4>
                <p className="attraction-description">
                  Known as the Peacock Village, this serene destination is home to a large population of peacocks. Ideal for nature walks, photography, and a peaceful escape from the city.
                </p>
              </div>
            </div>
          </div>

          {/* Attraction 5: Pabal Vigyan Ashram */}
          <div className="col-md-4">
            <div className="attraction-card">
              <div className="attraction-img pabal" >
                 <img src="glory stone images/Glowry Stone Resort/pabal.jpg" alt=""/>
              </div>
              <div className="attraction-info">
                <h4 className="attraction-title">Pabal Vigyan Ashram</h4>
                <p className="attraction-description">
                  The Pabal Vigyan Ashram is an innovative center for rural technology and science education. Learn about sustainable farming, rural development, and environmental practices.
                </p>
              </div>
            </div>
          </div>

          {/* Attraction 6: Pabal Jain Temple */}
          <div className="col-md-4">
            <div className="attraction-card">
              <div className="attraction-img" >
                <img src="glory stone images/Glowry Stone Resort/pabal jain.jpg" alt=""/>
              </div>
              <div className="attraction-info">
                <h4 className="attraction-title">Pabal Jain Temple</h4>
                <p className="attraction-description">
                  Visit the Pabal Jain Temple, a peaceful place of worship that offers a glimpse into Jainism's ancient philosophy. The intricate architecture and tranquil environment are perfect for reflection.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Resort Address */}
        <div className="text-center mt-5 address-section">
          <h3>Address</h3>
          <p>**Glowy Stone Resort**</p>
          <p>Wade Gavhan, Ahmednagar-Nagar Road,</p>
          <p>Tal. Parner, Dist. Ahilya Nagar (Ahmednagar), Maharashtra - 414302, India</p>
        </div>
      </div>
    </section>


        </>
    )
}