import Slider from "react-slick";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import './Page.css';
import GallerySlider from "./GallerySlider";
import Contactbox from "./Contactbox";
import RecentPost from "./RecentPost";
import Contact from "./Contact";
import ContactSection from "./ContactSection";
import { Helmet } from "react-helmet";





export default function Page(){

    const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question:
        "What is the distance of Grand Legacy inn hotels  from Pune International Airport?",
      answer:
        "Grand Legacy inn hotels  is conveniently located just a short drive from Pune International Airport. The hotel is approximately [insert distance or time, e.g., 5 kilometers or 15 minutes] from the airport, ensuring quick and easy access for travelers.",
    },
    {
      question: "Does Grand Legacy inn hotels  offer airport shuttle services?",
      answer:
        "Yes, Grand Legacy inn hotels  provides airport shuttle services for the convenience of its guests. Please contact the hotel in advance to arrange for shuttle services and to confirm availability.",
    },
    {
      question: "What is the check-in and check-out policy at Grand Legacy inn hotels ?",
       answer: "The standard check-in time at Grand Legacy inn hotels  is 12:00 PM, and check-out time is 11:00 AM. If you require an early check-in or late check-out, please inform us in advance, and we will do our best to accommodate your request based on room availability. Additional charges may apply for extended stays or early arrivals.",
   },
    {
      question: "Can I book a room at Grand Legacy inn hotels  for a few hours?",
      answer:
        "Yes, Grand Legacy inn hotels  offers flexible hourly booking options. This is ideal for travelers with layovers or those needing a brief rest between flights. Please contact the hotel directly to inquire about hourly rates and availability.",
    },
    {
      question: "What amenities are available at Grand Legacy inn hotels ?",
      answer:
        "Grand Legacy inn hotels  offers a range of amenities including [list key amenities, e.g., complimentary Wi-Fi, comfortable rooms, a 24-hour front desk, on-site dining, and a fitness center]. The hotel is designed to provide a comfortable and convenient stay for all guests.",
    },
    {
      question: "Is Wi-Fi available at Grand Legacy inn hotels ?",
      answer:
        "Yes, free high-speed Wi-Fi is available throughout the property at Grand Legacy inn hotels . Guests can stay connected and make use of internet services during their stay.",
    },
    {
      question: "Are there dining options available at Grand Legacy inn hotels ?",
      answer:
        "Yes, Grand Legacy inn hotels  features an on-site restaurant that offers a variety of dining options. Guests can enjoy [brief description of cuisine or dining options, e.g., local and international dishes] without having to leave the hotel.",
    },
    {
      question:
        "Does Grand Legacy inn hotels  have facilities for business travelers?",
      answer:
        "Yes, the hotel is equipped with business facilities including [mention facilities, e.g., a business center, meeting rooms, and printing services]. These amenities are designed to support business travelers and ensure a productive stay.",
    },
    {
      question:
        "Are there family-friendly amenities at Grand Legacy inn hotels ?",
      answer:
        "Grand Legacy inn hotels  provides several family-friendly amenities such as [list amenities, e.g., spacious rooms, extra bedding upon request, and kid-friendly meal options]. The hotel is well-suited for families traveling with children.",
    },
    {
      question: "What is the cancellation policy at Grand Legacy inn hotels ?",
      answer:
        "The cancellation policy at Grand Legacy inn hotels  varies depending on the rate type and booking conditions. Please review the cancellation policy during the booking process or contact the hotel directly for detailed information.",
    },
    {
     question: "How can I make a reservation at Grand Legacy inn hotels ?",
      answer: "Reservations can be made directly through our website, by phone at [+91 7058638153], or by email at [reservation@grandlegacyinnhotels.coms].",
     
    },
    {
      question: "Is parking available at Grand Legacy inn hotels ?",
      answer:
        "Yes, Grand Legacy inn hotels  offers parking facilities for guests. Please inquire about parking availability and any associated fees at the time of booking or upon arrival.",
    },
  ];
  
      const testimonials = [
          {
            quote: "I recently stayed at Grand Legacy inn hotels  while visiting Pune, and I couldn’t have asked for a better experience. The location in Kalyani Nagar is fantastic—close to all the local attractions and dining options. The room was impeccably clean and comfortable, with all the modern amenities I needed. What truly stood out was the exceptional service from the staff. They were friendly, attentive, and went above and beyond to make my stay enjoyable. I highly recommend Grand Legacy inn hotels  for anyone looking for a comfortable and convenient stay in Pune!",
            author: "- Mrs. Priya Sharma"
          },
          {
            quote: "Grand Legacy inn hotels  exceeded my expectations during my recent business trip to Pune. The hotel’s location in Kalyani Nagar is perfect, offering easy access to business districts and local amenities. The room was well-appointed and provided a peaceful retreat after a busy day. I was particularly impressed by the hotel’s business facilities and the helpfulness of the staff. They made sure I had everything I needed for a productive stay. If you’re looking for a great place to stay in Kalyani Nagar, Grand Legacy inn hotels  is the way to go.",
            author: "— Mr. Raj Patel"
          }
        ];
  
        
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
  
    const hotelImages = [
      {
        place: "Hotel-Images/Hotel In Viman Nagar.jpg",
        text: "Hotel In Viman Nagar",
        path:'hotel-in-viman-nagar'
      },
      {
        place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
        text: "Hotels in Viman Nagar for Stay",
        path:'viman-nagar-hotels-for-stay'
      },
      {
        place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
        text: "Lodge in Viman Nagar Pune",
        path:'lodge-in-viman-nagar-pune'
      },
      {
        place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
        text: "Hotels Near Viman Nagar Airport Pune",
        path:'hotels-near-viman-nagar-airport-pune'
      },
      {
        place: "Hotel-Images/Pune Airport Hotel.jpg",
        text: "Pune Airport Hotel",
        path:'pune-airport-hotel'
      },
      {
        place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
        text: "Hotel in Wadgaon Sheri",
        path:'hotel-wadgaon-sheri'
      },
      {
        place: "Hotel-Images/Online hotel booking in pune.jpg",
        text: "Online Hotel Booking in Pune",
        path:'online-hotel-booking-pune'
      },
      {
        place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
        text: "Hotel For Stay in Lohegaon Airport",
        path:'hotel-for-stay-lohegaon-airport'
      }
  
    
    ];




    const schemaData = {
      "@context": "https://schema.org",
      "@type": "Resort",
      "name": "Glowry Stone Resort",
      // keywrd imag
      "image": "https://www.glowrystone.com/images/",     
      "description": "Glowry Stone Resort offers the best one-day picnic spots near Ahmednagar. Perfect for family picnics, group outings, team-building, and couples seeking a relaxing getaway.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road",
        "addressLocality": "Parner",
        "addressRegion": "Maharashtra",
        "postalCode": "414302",
        "addressCountry": "IN"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 7066665554",
        "contactType": "Customer Service",
        "areaServed": "IN",
        "availableLanguage": "English"
      },
      "sameAs": [
        "https://www.facebook.com/glowrystone",
        "https://www.instagram.com/glowrystone"
      ],
      "url": "https://www.glowrystone.com"
    };

    
  
    return(
        <>
         <Helmet>
        {/* Title */}
        <title>
          Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort | One Day Trip Near Ahmednagar | Picnic Spots, Outings & Luxury Resorts
        </title>

        {/* Meta Tags */}
        <meta
          name="description"
          content="Looking for the best picnic spots near Ahmednagar? Glowry Stone Resort offers one-day trips, family outings, team outings, and luxury stays perfect for a relaxing getaway."
        />
        <meta
          name="keywords"
          content="picnic spot near Ahmednagar, one day trip near Ahmednagar, team outing places near Ahmednagar, best one day picnic spot near Ahmednagar, family picnic spots near Ahmednagar, best places for picnic in Ahmednagar, one day outing near Ahmednagar, couple picnic spots near Ahmednagar, best places to visit near Ahmednagar for a day trip, group picnic spots near Ahmednagar, summer picnic spots near Ahmednagar"
        />
        <meta name="author" content="Glowry Stone Resort" />
        <meta name="robots" content="index, follow" />

        {/* Open Graph for Social Sharing */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Glowry Stone Resort - Best Picnic Spots & One Day Trips Near Ahmednagar"
        />
        <meta
          property="og:description"
          content="Discover the best one-day picnic spots near Ahmednagar. Glowry Stone Resort is ideal for family outings, group picnics, corporate team outings, and romantic getaways."
        />
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" />
        <meta property="og:site_name" content="Glowry Stone Resort" />

        {/* Contact Information */}
        <meta
          name="contact"
          content="For reservations, please contact Glowry Stone Resort at +91 7066665554 or +91 9145557771. For inquiries, email us at reservation@glowrystone.com."
        />
        <meta
          name="address"
          content="Glowry Stone Resort, Wadegavhan, Ahmednagar-Nagar Road, Tal. Parner, Dist. Ahilya Nagar (Ahmednagar), Maharashtra - 414302, India"
        />
        <meta name="email" content="reservation@glowrystone.com" />
        <meta name="phone" content="+91 7066665554, +91 9145557771" />

        {/* Canonical Link */}
        <link rel="canonical" href="https://www.glowrystone.com" />

        {/* JSON-LD Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>

    <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
             <h2 className="text-white">Picnic Spot Near Ahmednagar</h2>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


    {/* ========================= */}

    <section className="trending recent-articles pb-6 keywordsection">
      <div className="container">
      <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="glory stone images/Glowry Stone Resort keyword/picnic spot near Ahmednagar.jpg" className="img-fluid" alt="picnic spot near Ahmednagar" />
              </div>
              <h2>
                Discover the Best Hotel Booking for Corporate Stays in Viman
                Nagar
              </h2>
              <p>
                When it comes to hotel booking for corporate stays in Viman
                Nagar, Grand Legacy inn hotels  stands out as the ideal choice for
                business professionals seeking comfort, convenience, and
                exceptional service. Our strategically located hotel caters
                specifically to the needs of corporate travelers, offering a
                range of amenities and services designed to enhance productivity
                and ensure a seamless business experience.
              </p>

              <h2>Best Hotel Booking for Corporate in Viman Nagar</h2>
              <p>
                Grand Legacy inn hotels  is renowned for being the best hotel
                booking choice for corporate stays in Viman Nagar. Our
                strategically located hotel caters to the unique needs of
                business travelers, offering a combination of comfort,
                convenience, and high-quality amenities. Positioned in the heart
                of Pune’s bustling business district, our hotel provides quick
                access to major IT parks, corporate offices, and key business
                hubs. With our comprehensive business facilities, including a
                state-of-the-art business center, high-speed Wi-Fi, and
                versatile meeting rooms, we ensure that your corporate stay is
                both productive and enjoyable.
              </p>

              <h2>Cheap Hotel Booking for Corporate in Viman Nagar</h2>
              <p>
                For those seeking cheap hotel booking for corporate stays in
                Viman Nagar, Grand Legacy inn hotels  offers an excellent balance
                of affordability and quality. We provide competitive rates that
                do not compromise on comfort or service, making it possible for
                business travelers to stay within budget while enjoying
                top-notch amenities. Our hotel features comfortable rooms,
                modern business facilities, and an on-site restaurant, all at
                economical prices that cater to cost-conscious professionals.
              </p>

              <h2>
                Cheap Hotel Booking for Corporate in Viman Nagar for Family
              </h2>
              <p>
                If you're looking for cheap hotel booking for corporate stays in
                Viman Nagar for family, Grand Legacy inn hotels  provides ideal
                accommodations that cater to both business and family needs. Our
                hotel offers spacious rooms that can comfortably accommodate
                families traveling with business professionals. With amenities
                such as high-speed Wi-Fi, a business center, and a variety of
                dining options, we ensure that both business and leisure needs
                are met, all at an affordable rate.
              </p>

              <h2>Hotel Room Rent Monthly in Viman Nagar</h2>
              <p>
                For extended stays, Grand Legacy inn hotels  offers competitive
                hotel room rent monthly in Viman Nagar. Our monthly rental
                options are perfect for business travelers who need long-term
                accommodation in Pune. With well-equipped rooms, a dedicated
                workspace, and access to all our business facilities, you can
                enjoy a comfortable and productive stay. Contact us directly to
                inquire about our monthly rates and special offers tailored for
                long-term stays.
              </p>

              <h2>Hotel Room Rent Monthly in Kharadi</h2>
              <p>
                In addition to Viman Nagar, Grand Legacy inn hotels  also provides
                hotel room rent monthly in Kharadi. Our Kharadi location offers
                the same high standards of comfort and business facilities as
                our Viman Nagar property. Ideal for professionals needing
                extended stays in the Kharadi area, our monthly rental options
                include spacious rooms with ergonomic workstations, high-speed
                internet, and easy access to local business hubs. Book with us
                for a convenient and cost-effective long-term stay solution in
                Kharadi.
              </p>

              <h2>Hotel Room Rent Monthly Near Pune Airport</h2>
              <p>
                When you need hotel room rent on a monthly basis near Pune
                Airport, Grand Legacy inn hotels  offers the perfect solution. Our
                conveniently located hotel provides comfortable and
                well-appointed rooms just a short drive from the airport, making
                it ideal for frequent travelers or business professionals with
                early flights. Enjoy the ease of access to airport facilities
                along with our exceptional monthly rates, which include
                high-speed Wi-Fi, modern amenities, and a range of business
                services to support your extended stay.
              </p>

              <h2>Hotel Room Rent Monthly Near Magarpatta City</h2>
              <p>
                For those seeking hotel room rent monthly near Magarpatta City,
                Grand Legacy inn hotels  is an excellent choice. Our hotel is
                strategically positioned to offer easy access to the vibrant
                Magarpatta business district, known for its IT parks and
                commercial hubs. Our monthly rental options provide a blend of
                comfort and convenience with spacious rooms, ergonomic
                workstations, and high-speed internet. Whether you’re in town
                for work or leisure, enjoy a productive and relaxing stay close
                to Magarpatta City.
              </p>

              <h2>Hotel on Rent Monthly Basis in Wadgaon Sheri</h2>
              <p>
                Grand Legacy inn hotels  also offers attractive monthly rental
                options in Wadgaon Sheri. Situated in a prominent business area,
                our hotel caters to professionals and families seeking extended
                stays. With well-furnished rooms, comprehensive business
                facilities, and easy access to local amenities, our hotel
                provides everything you need for a comfortable monthly stay.
                Enjoy the convenience of a central location combined with
                affordable rates and exceptional service.
              </p>

              <h2>Top Guest House for Monthly Rental in Pune</h2>
              <p>
                As one of the top guest houses for monthly rental in Pune,
                Grand Legacy Inn Hotels stands out for its excellent service and
                comfortable accommodations. Our hotel is designed to cater to
                long-term guests, offering flexible rental options and a range
                of amenities that make extended stays enjoyable. From
                well-equipped rooms to dedicated business services and dining
                options, we ensure that every guest feels at home during their
                stay.
              </p>

              <h2>Hotel Room Rent Monthly Basis in Pune</h2>
              <p>
                Grand Legacy inn hotels  provides a variety of options for hotel
                room rent on a monthly basis in Pune. Our competitive rates and
                high-quality accommodations are perfect for individuals or
                families needing long-term stays. Choose from our locations in
                Viman Nagar, Kharadi, and other prime areas, each offering
                unique benefits and proximity to key business hubs and
                amenities. Our monthly rentals include access to our
                comprehensive facilities, ensuring a comfortable and productive
                stay throughout your time in Pune.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Best hotel booking for corporate in Viman Nagar</p>
                      <p>- Cheap hotel booking for corporate in Viman Nagar</p>
                      <p>
                        - Cheap hotel booking for corporate in Viman Nagar for
                        family
                      </p>
                      <p>- Hotel room rent monthly in Viman Nagar</p>
                      <p>- Hotel room rent monthly in Kharadi</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Hotel room rent monthly near Pune Airport</p>
                      <p>- Hotel room rent monthly near Magarpatta City</p>
                      <p>- Hotel on rent monthly basis in Wadgaon Sheri</p>
                      <p>- Top Guest House for Monthly Rental in Pune</p>
                      <p>- Hotel room rent monthly basis in Pune</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>
                Why Choose Grand Legacy inn hotels  for Hotel Booking for
                Corporate in Viman Nagar?
              </h2>

              <p>
                When it comes to hotel booking for corporate stays in Viman
                Nagar, Grand Legacy inn hotels  is the premier choice for business
                professionals seeking a blend of comfort, convenience, and
                high-quality service. Here’s why our hotel stands out as the
                ideal option for your corporate needs:
              </p>

              <h2>1. Prime Location</h2>
              <p>
                Grand Legacy inn hotels  is strategically located in the heart of
                Viman Nagar, Pune's bustling business district. This prime
                location offers:
              </p>
              <ul>
                <li>
                  <strong>
                    Easy Access to Major IT Parks and Corporate Offices:
                  </strong>{" "}
                  Close proximity to key business hubs such as the EON IT Park
                  ensures that you can reach your meetings and client sites
                  quickly and efficiently.
                </li>
                <li>
                  <strong>Convenient Travel Connections:</strong> Just a short
                  drive from Pune International Airport and major transportation
                  links, making your travel hassle-free.
                </li>
              </ul>

              <h2>2. Comprehensive Business Facilities</h2>
              <p>
                Our hotel is designed to support your professional activities
                with a range of dedicated business amenities:
              </p>
              <ul>
                <li>
                  <strong>State-of-the-Art Business Center:</strong> Equipped
                  with the latest technology and office supplies to facilitate
                  your work needs.
                </li>
                <li>
                  <strong>High-Speed Wi-Fi:</strong> Reliable and fast internet
                  access throughout the hotel, ensuring you stay connected at
                  all times.
                </li>
                <li>
                  <strong>Versatile Meeting Rooms:</strong> Flexible meeting
                  spaces with audiovisual equipment, ideal for conferences,
                  presentations, and business discussions.
                </li>
              </ul>

              <h2>3. Comfortable and Functional Accommodations</h2>
              <p>
                Grand Legacy inn hotels  provides accommodations that cater
                specifically to the needs of corporate travelers:
              </p>
              <ul>
                <li>
                  <strong>Ergonomic Workstations:</strong> Each room features a
                  comfortable desk and chair, providing an ideal space for
                  working.
                </li>
                <li>
                  <strong>Premium Bedding:</strong> Ensures a restful sleep so
                  you can be at your best for important meetings and events.
                </li>
                <li>
                  <strong>Modern Amenities:</strong> Rooms are equipped with
                  flat-screen TVs, minibars, and well-appointed bathrooms for a
                  convenient and comfortable stay.
                </li>
              </ul>

              <h2>4. Exceptional Dining Options</h2>
              <p>
                Enjoy a variety of dining options without leaving the hotel:
              </p>
              <ul>
                <li>
                  <strong>On-Site Restaurant:</strong> Offering a diverse menu
                  of local and international dishes, perfect for business meals
                  or a relaxing dinner after a busy day.
                </li>
                <li>
                  <strong>Room Service:</strong> Available for those who prefer
                  to dine in the comfort of their room or need a meal after
                  late-night work.
                </li>
              </ul>

              <h2>5. Professional and Attentive Service</h2>
              <p>
                Our dedicated team at Grand Legacy inn hotels  is committed to
                providing outstanding service:
              </p>
              <ul>
                <li>
                  <strong>Personalized Assistance:</strong> From check-in to
                  check-out, our staff is here to cater to your specific needs
                  and requests.
                </li>
                <li>
                  <strong>24/7 Support:</strong> We offer round-the-clock
                  assistance to ensure that all aspects of your stay are handled
                  smoothly.
                </li>
              </ul>

              <h2>6. Competitive Corporate Rates</h2>
              <p>
                We offer special corporate rates that provide excellent value
                for high-quality accommodations and services. Booking directly
                with us ensures you receive the best rates and additional
                benefits tailored to business travelers.
              </p>

              <h2>7. Local Amenities and Attractions</h2>
              <p>
                While focusing on business, enjoy the convenience of being close
                to:
              </p>
              <ul>
                <li>
                  <strong>Shopping and Dining Options:</strong> Explore local
                  shops, restaurants, and recreational activities in Viman
                  Nagar.
                </li>
                <li>
                  <strong>Cultural and Entertainment Venues:</strong> Take
                  advantage of your downtime to experience Pune’s vibrant
                  cultural scene.
                </li>
              </ul>


              <div className="">
                <h2>
                  FAQs for Hotel in Viman Nagar, Pune - Grand Legacy inn hotels 
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

                {/* ======================== */}
                <div className="">
                  <h2>
                  Testimonials for Hotel Booking for Corporate in Viman Nagar -  Grand Legacy Inn Hotels{" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>

                <ContactSection></ContactSection>

</div>
<div class="col-12 col-md-4">
<RecentPost></RecentPost>
  <Contactbox></Contactbox>
</div> 
</div>
          
      </div>
    </section>

  <GallerySlider></GallerySlider>

        </>
    )
}  