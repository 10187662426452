import React from 'react';
import  { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css"; // Optional for basic styling
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Gallery.css"; // Optional custom CSS for styling
import { Helmet } from 'react-helmet';


export default function Photos() {
  const galleryData = [
       
    {
      imgSrc: "glory stone images/Glowry Stone Resort/Glowry stone resort galler1.jpg",
    
    },
    
    {
      imgSrc: "glory stone images/Glowry Stone Resort/Glowry Stone Resort dish.jpg",
    
    },
    {
      imgSrc: "glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery bedroom view.jpg",
  
    },
    {
      imgSrc: "glory stone images/Glowry Stone Resort/Glowry stone Resort gallery1.jpg",

    },
    {
      imgSrc: "glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery2.jpg",

    },
  
    {
      imgSrc: "glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery3.jpg",
      
    },
    {
      imgSrc: "glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery4.jpg",
    
    },
    {
      imgSrc: "glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery5.jpg",
    
    },

    {
      imgSrc: "glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery6.jpg",
  
    },
    {
      imgSrc: "glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery15.jpg",
      title: "Sample Image 3"
    },
    {
      imgSrc:
      "glory stone images/Glowry Stone Resort/Glowry Stone Resort hall.jpg",

    },
    {
      imgSrc:"glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery16.jpg",
      
    },
    {
      imgSrc:"glory stone images/Glowry Stone Resort/Glowry Stone Resort night view.jpg",
  
    },
    {imgSrc:'glory stone images/Glowry Stone Resort/Glowry Stone Resort swimming pool1.jpg',

    },
    {
      imgSrc:"glory stone images/Glowry Stone Resort/Glowry Stone Resort night view.jpg",
      
    },

  ];

      const [isOpen, setIsOpen] = useState(false);
      const [currentIndex, setCurrentIndex] = useState(0);
    
      const openLightbox = (index) => {
        setCurrentIndex(index);
        setIsOpen(true);
      };
    
  return (
    <>
    <Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - Attraction | One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune | Camping Places Near Pune
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Attraction | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.png",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>
     <div className="row ">
          {galleryData.map((item, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="gallery-item mb-4 rounded overflow-hidden">
                <div className="gallery-image" onClick={() => openLightbox(index)}>
                  <img src={item.imgSrc} alt={item.title} className="img-fluid" />
                </div>
                <div className="gallery-content">
                  <h5 className="white text-center position-absolute bottom-0 pb-4 left-50 mb-0 w-100">
                    {item.title}
                  </h5>
                  <ul>
                    <li>
                      <a href="#" onClick={(e) => { e.preventDefault(); openLightbox(index); }}>
                        <i className="fa fa-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>

         {/* Lightbox Component */}
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={galleryData.map((item) => ({ src: item.imgSrc }))}
          index={currentIndex}
          onIndexChange={setCurrentIndex}
        />
      )}
    </>
  )
}
