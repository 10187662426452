import React from 'react';
import { Link } from 'react-router-dom';  
import { Helmet } from 'react-helmet';

export default function Refund() {
  return (
   <>
   <Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - Refund Policy | One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune | Camping Places Near Pune
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Refund Policy | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.png",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>
   <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3">Refund Policy</h1>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                     <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Refund Policy
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>

    <section class="refund-policy">
  <div class="container">
    
    <p>At <strong>Glowry Stone Resort</strong>, we aim to provide our guests with a memorable and enjoyable experience. We understand that sometimes plans change, and in such cases, we are committed to making your cancellation and refund process as simple as possible. Please read our Refund Policy carefully before making a reservation.</p>

    <h3>1. Reservation and Booking</h3>
    <p>When you make a reservation with us, we require a deposit or full payment depending on the type of booking. This ensures your booking is confirmed and a spot is reserved for you at Glowry Stone Resort.</p>

    <h3>2. Cancellation and Refunds</h3>
    <p>We understand that sometimes you may need to cancel your reservation. To ensure fairness to both parties, we have outlined the following cancellation and refund policy:</p>
    <ul>
      <li><strong>Cancellation more than 7 days before check-in:</strong> You will receive a full refund of your payment, excluding any booking or transaction fees.</li>
      <li><strong>Cancellation between 3 and 7 days before check-in:</strong> A 50% refund will be issued, excluding any booking or transaction fees.</li>
      <li><strong>Cancellation less than 3 days before check-in:</strong> No refund will be provided.</li>
      <li><strong>No-show:</strong> In case of a no-show (failure to arrive for the reservation without prior notice), no refund will be provided.</li>
    </ul>

    <h3>3. Special Circumstances</h3>
    <p>In some cases, exceptions to the above policy may apply. These include:</p>
    <ul>
      <li><strong>Force Majeure:</strong> If a cancellation occurs due to reasons beyond your or our control, such as natural disasters, political unrest, or other emergencies, we will review each case individually and offer a full or partial refund or reschedule your stay, as appropriate.</li>
      <li><strong>Health or Medical Emergencies:</strong> We may consider offering a refund or rescheduling in the event of a medical emergency, provided appropriate documentation (e.g., a doctor's note) is submitted. Each case will be reviewed on an individual basis.</li>
    </ul>

    <h3>4. How to Request a Refund</h3>
    <p>If you are eligible for a refund, you can request it by contacting us directly via the following channels:</p>
    <ul>
      <li><strong>Email:</strong> [reservation@glowrystone.com]</li><br></br>
      <li><strong>Phone:</strong> [+91 706666555]</li><br></br>
      <li><strong>In-person:</strong> If you are at the resort and need to request a refund or cancellation, please visit our reception desk.</li>
    </ul>

    <p>Refunds will be processed back to the original method of payment within 7-10 business days, depending on your payment provider's processing time.</p>

    <h3>5. Changes to the Refund Policy</h3>
    <p>Glowry Stone Resort reserves the right to update or modify this Refund Policy at any time. Any changes will be posted on this page, and the "Last Revised" date at the bottom of the page will be updated. We encourage you to review this page periodically for any changes to the refund terms.</p>

    <h3>6. Contact Us</h3>
    <p>If you have any questions regarding this Refund Policy, or if you need assistance with cancellations or refunds, please don’t hesitate to contact us:</p>
    <ul>
    <ul>
      <li><strong>Phone:</strong> [+91 706666555]</li><br></br>
      <li><strong>Email:</strong> [reservation@glowrystone.com
      ]</li><br></br>
      <li><strong>Address:</strong> [ Glowry Stone Resort
Wadegavhan, Pune-Nagar Road,
Tal-.Parner,Dist- Ahilya Nagar (Ahmednagar).
 Maharashtra - India - 414302.]</li>
    </ul>

    </ul>

    <p><strong>Note:</strong> By making a booking, you agree to the terms and conditions set forth in this Refund Policy.</p>

  </div>
</section>

   </>
  )
}
