import { Link } from 'react-router-dom';  
import { Helmet } from 'react-helmet';
export default function Terms(){
    return(
        <>

<Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - Terms & Condition | One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune | Camping Places Near Pune
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Terms & condition | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.png",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>
           <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3">Terms & Condition</h1>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                     <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Terms & Condition
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


    <section class="terms-conditions">
  <div class="container">
  
    <p>Welcome to <strong>Glowry Stone Resort</strong>. By accessing our services and booking your stay with us, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully.</p>

    <h3>1. General Information</h3>
    <p>Glowry Stone Resort is a hospitality and leisure provider located in the serene countryside. We aim to offer a relaxing and enjoyable experience for all our guests. These Terms and Conditions apply to all reservations, bookings, and usage of the resort facilities and services.</p>

    <h3>2. Reservation and Booking</h3>
    <ul>
      <li><strong>Booking Process:</strong> Bookings at Glowry Stone Resort can be made through our website, via email, or by calling our reservation desk.</li>
      <li><strong>Booking Confirmation:</strong> A reservation is only confirmed once you receive a confirmation email or message from Glowry Stone Resort.</li>
      <li><strong>Payment:</strong> A partial or full payment may be required at the time of booking, depending on the booking type and service. We accept payments via credit card, debit card, and bank transfer.</li>
      <li><strong>Rates:</strong> The rates are subject to change at any time and may vary depending on seasonality and demand. The rate confirmed at the time of booking will apply.</li>
    </ul>

    <h3>3. Check-in and Check-out</h3>
    <ul>
      <li><strong>Check-in:</strong> Check-in time is from <strong>2:00 PM</strong> onwards.</li>
      <li><strong>Check-out:</strong> Check-out time is <strong>11:00 AM</strong>. Late check-out may be available upon request and subject to availability and additional charges.</li>
      <li><strong>Early Check-in:</strong> Early check-ins may be available for an additional charge, depending on availability.</li>
    </ul>

    <h3>4. Cancellations and Modifications</h3>
    <ul>
      <li><strong>Cancellation Policy:</strong> 
        <ul>
          <li>For <strong>standard bookings</strong>, cancellations made <strong>48 hours</strong> or more before check-in will receive a full refund.</li>
          <li>For cancellations made <strong>less than 48 hours</strong> before check-in, the booking will be non-refundable.</li>
        </ul>
      </li>
      <li><strong>Modification Policy:</strong> Modifications to bookings can be made subject to availability. If the new dates or services requested cost more than the original booking, the difference must be paid.</li>
    </ul>

    <h3>5. Guest Responsibilities</h3>
    <ul>
      <li><strong>Damages:</strong> Guests are responsible for any damage caused to resort property during their stay. In case of damages, the guest will be charged for repairs or replacement costs.</li>
      <li><strong>Behavior:</strong> Guests are expected to maintain appropriate behavior and show respect to other guests and resort staff. Disorderly conduct, excessive noise, or illegal activities will not be tolerated.</li>
      <li><strong>Age Restrictions:</strong> Children must be accompanied by an adult. Certain areas of the resort may have age restrictions for safety reasons.</li>
    </ul>

    <h3>6. Facilities and Activities</h3>
    <ul>
      <li><strong>Use of Facilities:</strong> All resort facilities (e.g., swimming pools, gym, dining areas, outdoor activities) are provided subject to availability and any specific operational hours.</li>
      <li><strong>Outdoor Activities:</strong> Activities such as trekking, zip-lining, rock climbing, etc., are provided by third-party operators, and Glowry Stone Resort is not liable for any accidents, injuries, or damages incurred during these activities. Guests must sign any necessary waivers before participating.</li>
      <li><strong>Wellness and Spa Services:</strong> Wellness services, including spa and massage treatments, are available for an additional charge. These services must be booked in advance.</li>
    </ul>

    <h3>7. Privacy and Data Protection</h3>
    <p>Glowry Stone Resort respects your privacy and is committed to protecting your personal information. Any data provided to us during the booking process is stored securely and only used for resort-related purposes. We will not share your personal data with third parties unless required by law or necessary for processing payments.</p>

    <h3>8. Pets Policy</h3>
    <p>Pets are <strong>not</strong> allowed at Glowry Stone Resort unless stated otherwise in the booking confirmation. For special requests regarding pets, please contact us prior to booking.</p>

    <h3>9. Liability</h3>
    <p>Glowry Stone Resort is not responsible for any loss, damage, or injury that occurs to guests during their stay or participation in resort activities. Guests are responsible for their personal belongings and should take necessary precautions to ensure the safety of their valuables.</p>

    <h3>10. Force Majeure</h3>
    <p>Glowry Stone Resort shall not be held liable for any failure to perform its obligations due to events beyond its control, including but not limited to, natural disasters, pandemics, strikes, or government actions. In such cases, we will make reasonable efforts to accommodate guests, but no compensation will be provided.</p>

    <h3>11. Amendments to the Terms and Conditions</h3>
    <p>Glowry Stone Resort reserves the right to modify or update these Terms and Conditions at any time without prior notice. All changes will be posted on our website. The updated Terms and Conditions will apply to all bookings made after the date of modification.</p>

    <h3>12. Governing Law and Jurisdiction</h3>
    <p>These Terms and Conditions are governed by the laws of <strong>[Country/State]</strong>. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in <strong>[City/State]</strong>.</p>

    <h3>13. Contact Information</h3>
    <p>For inquiries, reservations, or assistance, please contact us at:</p>
    <ul>
      <li><strong>Phone:</strong> [+91 706666555]</li>
      <li><strong>Email:</strong> [reservation@glowrystone.com
      ]</li>
      <li><strong>Address:</strong> [ Glowry Stone Resort
Wadegavhan, Pune-Nagar Road,
Tal-.Parner,Dist- Ahilya Nagar (Ahmednagar).
 Maharashtra - India - 414302.]</li>
    </ul>

    <p><strong>Final Note:</strong> We hope you have an unforgettable experience at Glowry Stone Resort. By making a booking, you acknowledge and accept these Terms and Conditions.</p>

  </div>
</section>


                    
        </>
    )
}