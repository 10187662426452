import Slider from "react-slick";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import './Page.css';
import GallerySlider from "./GallerySlider";
import Contactbox from "./Contactbox";
import RecentPost from "./RecentPost";
import Contact from "./Contact";
import ContactSection from "./ContactSection";
import { Helmet } from 'react-helmet';


export default function ActivitiesResortNearPune(){

    const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What types of activities are available at Glowry Stone Resort?",
      answer: "At Glowry Stone Resort, we offer a wide range of activities to suit all types of guests. Activities include adventure sports like rock climbing, rappelling, zip lining, ATV rides, and trekking. Additionally, we provide nature walks, birdwatching, boating, cycling, and various outdoor games like volleyball, cricket, and badminton."
    },
    {
      question: "How far is Glowry Stone Resort from Pune?",
      answer: "Glowry Stone Resort is conveniently located about 70-80 km from Pune, making it easily accessible for a quick getaway. It takes approximately 2 hours by car to reach the resort, perfect for a day trip or a weekend stay."
    },
    {
      question: "Is Glowry Stone Resort suitable for family outings?",
      answer: "Yes, Glowry Stone Resort is an ideal destination for family outings. With a range of family-friendly activities such as nature walks, cycling, boating, and outdoor games, the resort ensures that all family members, from kids to adults, have a fun-filled and memorable experience."
    },
    {
      question: "Can we visit Glowry Stone Resort for a one-day outing?",
      answer: "Absolutely! Glowry Stone Resort is perfect for a one-day outing near Pune. You can enjoy a day of thrilling adventure sports, team-building activities, or simply relax in the natural surroundings. The resort offers day packages that include all the activities and meals for a complete experience."
    },
    {
      question: "What adventure activities are available at the resort?",
      answer: "Glowry Stone Resort is known for its exciting adventure activities. Some of the highlights include: \n• Zip lining \n• Rock climbing \n• Rappelling \n• ATV rides \n• Trekking \n• Paintball. These activities are designed to give you an adrenaline rush while allowing you to enjoy the natural beauty of the resort."
    },
    {
      question: "Is the resort suitable for corporate outings or team-building events?",
      answer: "Yes, Glowry Stone Resort is an excellent choice for corporate outings and team-building events. We offer a range of team-building activities such as group challenges, treasure hunts, and problem-solving games that foster collaboration and teamwork. The resort also provides spacious meeting areas for corporate workshops or seminars."
    },
    {
      question: "What are the accommodation options available at Glowry Stone Resort?",
      answer: "Glowry Stone Resort offers a variety of comfortable accommodation options, including spacious cottages and tented camps. The rooms are well-appointed with modern amenities, ensuring a relaxing stay. Guests can enjoy beautiful views of nature from their rooms, making it a perfect place to unwind after an action-packed day."
    },
    {
      question: "Can we enjoy a bonfire at the resort?",
      answer: "Yes, Glowry Stone Resort offers a bonfire experience for guests in the evenings. After a day full of activities, you can relax by the bonfire, enjoy some snacks, and make lasting memories with friends and family under the stars."
    },
    {
      question: "Are meals provided at the resort?",
      answer: "Yes, Glowry Stone Resort offers delicious buffet meals as part of the stay. The resort serves a variety of local and continental dishes, catering to different dietary preferences. You can enjoy your meals in the open-air dining area or in the comfort of your room, depending on your choice."
    },
    {
      question: "Is Glowry Stone Resort pet-friendly?",
      answer: "Currently, Glowry Stone Resort does not allow pets on the property. However, the resort is located in a peaceful and natural setting, making it an ideal getaway for animal lovers to connect with nature and enjoy the outdoors."
    }
  ];
  
  const testimonials = [
    {
      quote: "Glowry Stone Resort is hands down one of the best activity resorts near Pune! We went there for a weekend getaway with friends, and it was an unforgettable experience. The variety of adventure activities like zip-lining, rock climbing, and ATV rides kept everyone engaged and excited throughout the trip. The staff was extremely friendly and professional, making sure we were safe and had a great time. The resort’s beautiful location amidst nature added to the charm. We also enjoyed a relaxing nature walk and the evening bonfire. If you're looking for a fun-filled, adventurous, and relaxing retreat, I highly recommend Glowry Stone Resort!",
      author: "— Mr. Ravi S., Pune"
    },
    {
      quote: "Our family had an incredible time at Glowry Stone Resort! We wanted to do something different for our family outing, and this resort was the perfect choice. The kids loved the boating and cycling activities, while the adults had a blast with trekking and rappelling. The resort is very family-friendly, and the activities are suitable for all ages. The food was fantastic, and the accommodation was comfortable with beautiful views. We ended our day with a relaxing bonfire, making it a perfect evening. Glowry Stone Resort truly offers the best combination of adventure and relaxation just a short drive from Pune. Highly recommend it for family getaways!",
      author: "— Mrs. Neha P., Mumbai"
    }
  ];
  
  
        
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
  
   
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "Resort",
      "name": "Glowry Stone Resort Near Pune - Adventure & Activity Resort",
      "image": "https://www.glowrystone.com/glory stone images/Glowry Stone Resort keyword/Activities Resort Near Pune.jpg", // Replace with actual image URL
      "description": "Glowry Stone Resort near Pune offers the perfect getaway with adventure activities, team-building exercises, picnic spots, and family-friendly amenities. Book your corporate outing or family retreat today at one of the best activity resorts near Pune.",
      "location": {
        "@type": "Place",
        "name": "Glowry Stone Resort",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road",
          "addressLocality": "Parner",
          "addressRegion": "Maharashtra",
          "postalCode": "414302",
          "addressCountry": "IN"
        }
      },
      "url": "https://www.glowrystone.com/",
      "sameAs": [
        "https://www.facebook.com/glowrystone",
        "https://www.instagram.com/glowrystone"
      ],
      "offers": {
        "@type": "Offer",
        "url": "https://www.glowrystone.com",
        "priceCurrency": "INR",
        "price": "Starting from INR 400", // Adjust based on actual price
        "eligibleRegion": "IN",
        "eligibleQuantity": {
          "@type": "QuantitativeValue",
          "value": 50,
          "unitCode": "C62"
        }
      },
      "performer": {
        "@type": "Organization",
        "name": "Glowry Stone Resort",
        "sameAs": "https://www.glowrystone.com"
      }
    };
  
  
    return(
        <>

<Helmet>
      {/* Title Tag */}
      <title>
      Contact: +91 7066665554 / +91 9145557771 |Glowry Stone Resort | Best Adventure & Activity Resorts Near Pune | Glowry Stone Resort with Activities
      </title>

      {/* Meta Tags */}
      <meta
        name="description"
        content="Glowry Stone Resort near Pune offers exciting adventure activities, perfect for family outings, corporate events, team-building exercises, and day picnics. Book your adventure-filled day trip or overnight stay now!"
      />
      <meta
        name="keywords"
        content="Resorts near Pune with activities, adventure resorts near Pune, one day picnic resorts with activities near Pune, resorts near Pune for corporate outing, resorts with adventure activities near Pune, best activity resorts near Pune, adventure sports resort near Pune, day outing resorts near Pune, one day activity resort near Pune, best resorts near Pune with activities, resorts for family with activities near Pune"
      />
      <meta name="author" content="Glowry Stone Resort" />
      <meta name="robots" content="index, follow" />

      {/* Open Graph for Social Sharing */}
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Best Adventure & Activity Resorts Near Pune | Glowry Stone Resort with Activities"
      />
      <meta
        property="og:description"
        content="Glowry Stone Resort offers thrilling adventure activities, corporate outing options, and family-friendly amenities near Pune. Book your adventure-filled stay or day trip today!"
      />
      <meta property="og:url" content="https://www.glowrystone.com/Activities-Resort-Near-Pune" />
      <meta property="og:image" content="https://www.glowrystone.com/glory stone images/Glowry Stone Resort keyword/Activities Resort Near Pune.jpg" />
      <meta property="og:site_name" content="Glowry Stone Resort" />

      {/* Contact Information */}
      <meta
        name="contact"
        content="For reservations, please contact Glowry Stone Resort at +91 7066665554 or +91 9145557771. Email: reservation@glowrystone.com"
      />
      <meta
        name="address"
        content="Glowry Stone Resort, Wadegavhan, Ahmednagar-Nagar Road, Tal. Parner, Dist. Ahilya Nagar (Ahmednagar), Maharashtra - 414302, India"
      />
      <meta name="email" content="reservation@glowrystone.com" />
      <meta name="phone" content="+91 7066665554, +91 9145557771" />

      {/* Canonical Link */}
      <link rel="canonical" href="https://www.glowrystone.com/" />

      {/* JSON-LD Schema Markup */}
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
    <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3 text-white"> Activities Resort Near Pune</h2>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                   <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


    {/* ========================= */}

    <section className="trending recent-articles pb-6 keywordsection">
      <div className="container">
      <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="glory stone images/Glowry Stone Resort keyword/Activities Resort Near Pune.jpg" className="img-fluid" alt="Activity Resort Near Pune" />
              </div>
              <h2>Glowry Stone Resort: The Ultimate Activity Resort Near Pune</h2>
<p>If you're looking for an activities resort near Pune, Glowry Stone Resort should be at the top of your list. Situated just a short drive from the bustling city of Pune, Glowry Stone Resort offers a perfect blend of adventure, relaxation, and nature. Whether you're planning a weekend getaway, a family retreat, or a romantic escape, this resort offers an unparalleled experience for those seeking both outdoor excitement and indoor comfort.</p>

<h3>Adventure Resorts Near Pune: Thrilling Activities at Glowry Stone Resort</h3>
<p>For adventure enthusiasts, Glowry Stone Resort is one of the best adventure resorts near Pune. Set amidst lush landscapes and serene lakes, the resort offers a wide range of outdoor activities like trekking, rock climbing, rappelling, zip lining, and ATV rides. Whether you’re a beginner or an experienced adventurer, these activities are designed to provide a fun and thrilling experience for everyone.</p>

<h3>One Day Picnic Resorts with Activities Near Pune</h3>
<p>Looking for a one-day picnic resort with activities near Pune? Glowry Stone Resort is an ideal option. The resort is easily accessible for a quick day trip, where you can enjoy outdoor adventures such as cycling, boating, and team-building games. After a day filled with exciting activities, you can relax by the lake or enjoy a bonfire under the stars. It’s the perfect place to unwind and recharge in just one day.</p>

<h3>Resorts Near Pune for Corporate Outing: Team Building at Glowry Stone Resort</h3>
<p>Glowry Stone Resort is one of the top resorts near Pune for corporate outings. The resort is designed to host team-building events, corporate workshops, and group activities. It provides a range of activities that help strengthen team dynamics, such as group challenges, treasure hunts, and problem-solving games. The serene environment and the variety of outdoor activities make it a great spot for fostering collaboration and boosting team morale.</p>

<h3>Resorts with Activities Near Pune: All-in-One Destination</h3>
<p>Glowry Stone Resort is not just about accommodation—it’s about creating memorable experiences. As one of the best resorts with activities near Pune, the resort offers something for every kind of traveler. Whether you're interested in nature walks, adventure sports, or relaxation, the resort offers a wide variety of outdoor activities and amenities that cater to different preferences. It’s an ideal getaway for families, friends, and groups alike.</p>

<h3>Resorts with Adventure Activities Near Pune: Thrilling Fun for All</h3>
<p>For those seeking more than just a relaxing stay, Glowry Stone Resort stands out as one of the best resorts with adventure activities near Pune. Engage in heart-pumping adventures like zip lining, rock climbing, boating, and more. The resort is designed to offer guests a variety of action-packed experiences, ensuring an unforgettable adventure-filled escape just outside Pune.</p>

<h3>Resorts for Corporate Outing Near Pune: Perfect for Team-Building Activities</h3>
<p>Looking for the perfect venue for your corporate outing near Pune? Look no further than Glowry Stone Resort. The resort provides an ideal setting for team-building activities, corporate meetings, and group outings. Whether it’s problem-solving games, outdoor team challenges, or interactive workshops, the resort offers a productive yet fun environment for companies looking to bond and engage in team-building activities.</p>

<h3>Resort Near Pune with Activities: Unmatched Variety and Fun</h3>
<p>Glowry Stone Resort is a top choice for a resort near Pune with activities. Whether you’re seeking adventure, relaxation, or team-building experiences, the resort offers a comprehensive range of activities. From nature trails to adrenaline-pumping outdoor sports, Glowry Stone Resort has something for everyone. It’s the ultimate destination for those looking to combine adventure, relaxation, and memorable experiences near Pune.</p>

<h3>Resorts Near Pune for Family with Activities: A Fun-Filled Family Getaway</h3>
<p>For families looking for an exciting yet relaxing destination, Glowry Stone Resort is one of the best resorts near Pune for family with activities. The resort offers a variety of fun, family-friendly activities, including:</p>
<ul>
    <li>Nature walks for a peaceful family bonding experience</li>
    <li>Cycling trails through lush greenery</li>
    <li>Boating on the serene lake</li>
    <li>Bonfire nights with the family</li>
    <li>Outdoor games like cricket, badminton, and volleyball</li>
</ul>
<p>The combination of adventure, nature, and leisure activities makes Glowry Stone Resort the ideal family destination near Pune.</p>

<h3>One Day Adventure Resort Near Pune: Quick Getaway with Thrilling Activities</h3>
<p>Looking for a one day adventure resort near Pune? Glowry Stone Resort is perfect for those who want to experience a day of excitement and adventure without spending the night. The resort is just a short drive from Pune, making it an ideal spot for a day-out adventure. Some of the thrilling activities you can enjoy during your visit include:</p>
<ul>
    <li>Trekking through scenic trails</li>
    <li>Rock climbing and rappelling</li>
    <li>Zip lining for the adrenaline rush</li>
    <li>ATV rides for an off-road adventure</li>
</ul>
<p>Whether you’re with friends, family, or colleagues, Glowry Stone Resort offers a jam-packed day of adventure sports and team-building activities in one convenient location.</p>

<h3>Activity Resorts in Pune: Everything You Need for Fun and Adventure</h3>
<p>Glowry Stone Resort is not just a place to stay, it’s a hub for exciting activities. As one of the leading activity resorts in Pune, it features a range of adventure games and sports that will keep you entertained throughout your stay. Whether you're into extreme sports or just looking to relax with some outdoor games, Glowry Stone Resort has something for everyone:</p>
<ul>
    <li>Trekking and nature trails</li>
    <li>Outdoor adventure games like paintball, treasure hunts, and team-building challenges</li>
    <li>Rock climbing and rappelling</li>
    <li>Boating and fishing in the resort’s serene lake</li>
</ul>
<p>These activities are perfect for groups, families, and friends looking to experience an action-packed day together.</p>

<h3>Adventure Activities Resort Near Pune: Thrill and Nature Combined</h3>
<p>If you’re specifically looking for a resort with adventure activities near Pune, Glowry Stone Resort stands out as one of the best options. Offering a wide range of adventure sports, including ATV rides, rock climbing, zip lining, and more, Glowry Stone Resort is a must-visit destination for those who want to add some excitement to their getaway. Whether you're an adrenaline junkie or someone who enjoys a mix of thrill and relaxation, this resort offers the best of both worlds.</p>

<h3>Adventure Games Resort Near Pune: Fun and Challenge for Everyone</h3>
<p>At Glowry Stone Resort, adventure games are not just about competition—they’re about fun, teamwork, and challenges. The resort offers a variety of outdoor games that can be enjoyed in groups or as individuals, making it a great destination for both corporate outings and family trips. Some of the adventure games available include:</p>
<ul>
    <li>Obstacle courses for all levels of fitness</li>
    <li>Paintball for a fun team-building experience</li>
    <li>Scavenger hunts and treasure hunts</li>
    <li>Archery and target shooting for precision-based fun</li>
</ul>
<p>These adventure games are perfect for people who enjoy challenges, teamwork, and healthy competition.</p>

<h3>Adventure Resort Pune Day Out: A Day Full of Fun and Excitement</h3>
<p>For those looking for a day out filled with adventure, Glowry Stone Resort offers the perfect solution. This adventure resort in Pune allows you to spend the day enjoying exciting activities such as trekking, rappelling, zip lining, and more. The resort’s outdoor adventure activities are designed to keep you engaged and active throughout the day, making it a perfect option for a day outing with friends, family, or colleagues.</p>

<h3>Adventure Resorts in Pune: A Complete Adventure Package</h3>
<p>Looking for the best adventure resorts in Pune? Glowry Stone Resort is an all-in-one adventure destination. It combines action-packed adventure sports with serene nature experiences, making it ideal for both adrenaline lovers and nature enthusiasts. Some activities to enjoy include:</p>
<ul>
    <li>Trekking and hiking through scenic trails</li>
    <li>Rappelling and rock climbing for a challenge</li>
    <li>Boating on the tranquil lake</li>
    <li>Adventure sports like ATV rides, zip lining, and paintball</li>
</ul>
<p>If you're seeking a complete adventure resort experience near Pune, Glowry Stone Resort offers an unforgettable escape filled with excitement, nature, and relaxation.</p>

<h3>Adventure Sports Resort Near Pune: Unleash Your Inner Adventurer</h3>
<p>For adventure sports lovers, Glowry Stone Resort is one of the top adventure sports resorts near Pune. The resort offers a range of heart-pumping activities, including:</p>
<ul>
    <li>ATV off-roading through rough terrains</li>
    <li>Zip lining across valleys and forests</li>
    <li>Rock climbing and rappelling on rugged cliffs</li>
    <li>Boating in the resort’s scenic lake</li>
</ul>


              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- Resorts near Pune with activities</p>
<p>- Adventure resorts near Pune</p>
<p>- One day picnic resorts with activities near Pune</p>
<p>- Resorts near Pune for corporate outing</p>
<p>- Resort with activities near Pune</p>
<p>- Resorts with adventure activities near Pune</p>
<p>- Resorts for corporate outing near Pune</p>
<p>- Resort near Pune with activities</p>
<p>- Resorts in Pune with activities</p>
<p>- Resorts near Pune for family with activities</p>
<p>- One day adventure resort near Pune</p>




                    </td>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- Adventure resorts in Pune</p>
<p>- Adventure sports resort near Pune</p>
<p>- Best activity resorts near Pune</p>
<p>- Best resort with activities near Pune</p>
<p>- Best resorts near Pune with activities</p>
<p>- Day outing resorts near Pune</p>
<p>- Day trip resorts near Pune</p>
<p>- One day outing resort near Pune</p>
<p>- One day activity resort near Pune</p>
<p>- Adventure games resort near Pune</p>
<p>- Adventure resort Pune day out</p>
<p>- Activity resorts in Pune</p>
<p>- Adventure activities resort near Pune</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>Why Choose Glowry Stone Resort for Activities Near Pune?</h2>
<p>When it comes to activity resorts near Pune, Glowry Stone Resort stands out as the ultimate destination for those seeking adventure, relaxation, and memorable experiences. Here’s why you should choose Glowry Stone Resort for your next getaway:</p>

<h3>1. Wide Range of Activities for All Ages and Interests</h3>
<p>At Glowry Stone Resort, we understand that every guest has different preferences when it comes to activities. That's why we offer a diverse range of adventure sports, team-building games, and nature-based experiences, making it the perfect place for all types of travelers.</p>
<ul>
    <li>Adventure sports like rock climbing, zip lining, and ATV rides for adrenaline seekers.</li>
    <li>Nature trails, birdwatching, and boating for those who want to connect with nature.</li>
    <li>Outdoor games such as badminton, volleyball, and football for fun-filled moments with family and friends.</li>
</ul>
<p>Whether you're a family, a couple, or a group of friends, you’ll find activities that everyone will enjoy at Glowry Stone Resort.</p>

<h3>2. Ideal Location for a Quick Escape</h3>
<p>Located just a short drive from Pune, Glowry Stone Resort is the perfect place for a quick weekend getaway or a day outing. Its proximity to the city means you don’t have to travel far to experience the tranquility of nature combined with the thrill of adventure.</p>
<ul>
    <li>Easily accessible, making it the perfect option for a day trip or weekend escape.</li>
    <li>Located amidst serene landscapes, providing a peaceful environment for relaxation and fun-filled activities.</li>
    <li>One-day adventure trips with a combination of adventure sports and nature experiences.</li>
</ul>

<h3>3. Perfect for Corporate Outings and Team-Building Activities</h3>
<p>Glowry Stone Resort is not just for families and couples—it's also an excellent venue for corporate outings and team-building exercises. We offer a variety of group activities designed to enhance teamwork and communication while having fun.</p>
<ul>
    <li>Team-building challenges like treasure hunts, problem-solving games, and group challenges.</li>
    <li>Spacious venues for corporate workshops, conferences, and meetings.</li>
    <li>Adventure sports that help foster collaboration and break down barriers between team members.</li>
</ul>
<p>Glowry Stone Resort is the ideal destination for corporate events looking to combine work and play in a natural, relaxed environment.</p>

<h3>4. High-Quality Facilities and Comfortable Accommodation</h3>
<p>In addition to the wide variety of activities, Glowry Stone Resort offers comfortable accommodations and top-notch facilities that make your stay relaxing and convenient.</p>
<ul>
    <li>Spacious rooms and cottages designed for comfort and relaxation.</li>
    <li>Bonfire arrangements, dining options, and recreation areas to unwind after an active day.</li>
    <li>Picnic areas, lakeside views, and outdoor spaces to enjoy peaceful moments in nature.</li>
</ul>
<p>Whether you’re looking for a relaxing retreat or a place to recharge after an active day, our accommodations provide the perfect blend of comfort and nature.</p>

<h3>5. A Safe and Family-Friendly Environment</h3>
<p>Glowry Stone Resort is a family-friendly destination, offering a safe and welcoming environment for guests of all ages. From children's play areas to group activities, everyone can enjoy a fulfilling and safe experience.</p>
<ul>
    <li>Family-oriented activities like nature walks, cycling, and outdoor games.</li>
    <li>Well-trained staff to ensure safety during adventure activities.</li>
    <li>Relaxing and serene surroundings perfect for family bonding and quality time together.</li>
</ul>
<p>We are committed to providing a secure, enjoyable environment where you and your loved ones can create lasting memories.</p>

<h3>6. Customized Packages for Every Occasion</h3>
<p>Whether you're planning a family vacation, a corporate retreat, or a group outing, Glowry Stone Resort offers customized packages to suit your specific needs. From adventure activities to meal options and team-building programs, we can tailor your experience to make it unforgettable.</p>
<ul>
    <li>Group discounts and special rates for corporate outings and family groups.</li>
    <li>Customized team-building workshops and adventure packages.</li>
    <li>Flexible day trip packages to maximize your time and experience.</li>
</ul>



              <div className="">
                <h2>
                FAQs for Glowry Stone Resort: Activities Resort Near Pune?
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

                {/* ======================== */}
                <div className="">
                  <h2>
                  Testimonials for Glowry Stone Resort: Activities Resort Near Pune?{" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>

                <ContactSection></ContactSection>

            </div>
          <div class="col-12 col-md-4">
            <RecentPost></RecentPost>
              <Contactbox></Contactbox>
          </div> 
          </div>
      </div>
    </section>

  <GallerySlider></GallerySlider>

        </>
    )
}  
