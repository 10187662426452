import { Link } from 'react-router-dom';  
import { Helmet } from 'react-helmet';

export default function Policy(){
    return(
        <>

<Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - Privacy & Policy| One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune | Camping Places Near Pune
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Perivacy & Policy| Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.png",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>

<section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3">Privacy & Policy</h1>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                     <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Privacy & Policy
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>
          <section class="privacy-policy">
  <div class="container">
    
    <p>At <strong>Glowry Stone Resort</strong>, we are committed to protecting your privacy. This Privacy Policy outlines the types of personal information we collect, how we use it, and the measures we take to protect your information. By using our services, booking through our website, or interacting with us in any way, you agree to the practices described in this Privacy Policy.</p>

    <h3>1. Information We Collect</h3>
    <p>We collect various types of information to provide you with an enhanced experience while using our services. This includes:</p>
    <ul>
      <li><strong>Personal Information:</strong> When you make a reservation, we collect personal information such as your name, email address, phone number, payment details, and address.</li>
      <li><strong>Usage Data:</strong> We collect information about how you interact with our website, including IP address, browser type, pages visited, time spent on the site, and other diagnostic data.</li>
      <li><strong>Payment Information:</strong> We may collect payment details such as credit card information or bank account numbers for processing bookings and transactions.</li>
    </ul>

    <h3>2. How We Use Your Information</h3>
    <p>Your information is used for various purposes, including:</p>
    <ul>
      <li><strong>Reservation and Booking:</strong> To process your reservations and provide confirmation of bookings.</li>
      <li><strong>Communication:</strong> To send you updates about your bookings, promotions, special offers, and other resort-related news that may be of interest to you.</li>
      <li><strong>Improve Our Services:</strong> To enhance and personalize your experience at Glowry Stone Resort by understanding your preferences and behaviors.</li>
      <li><strong>Transaction Processing:</strong> To facilitate payment processing and refunds, and to detect and prevent fraudulent activities.</li>
    </ul>

    <h3>3. How We Protect Your Information</h3>
    <p>We take the security of your personal information seriously. Our website uses industry-standard encryption (SSL) to protect your data during transmission. Additionally, we store personal data securely and follow best practices to safeguard it from unauthorized access, loss, or misuse.</p>

    <h3>4. Sharing of Your Information</h3>
    <p>We will not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:</p>
    <ul>
      <li><strong>Service Providers:</strong> We may share your information with third-party vendors who assist with processing payments, reservations, or providing other services related to your stay at the resort.</li>
      <li><strong>Legal Compliance:</strong> If required by law or in response to a valid legal request, we may disclose your information to government authorities or law enforcement agencies.</li>
      <li><strong>Business Transfers:</strong> If Glowry Stone Resort is involved in a merger, acquisition, or sale of assets, your personal information may be transferred as part of the transaction.</li>
    </ul>

    <h3>5. Cookies and Tracking Technologies</h3>
    <p>We use cookies and similar tracking technologies to improve your experience on our website. Cookies are small text files stored on your device that help us analyze web traffic and provide personalized content.</p>
    <ul>
      <li><strong>Analytics Cookies:</strong> To track user activity and optimize website performance.</li>
      <li><strong>Advertising Cookies:</strong> To display relevant ads based on your preferences.</li>
    </ul>
    <p>You can control the use of cookies through your browser settings. However, disabling cookies may affect the functionality of some parts of the website.</p>

    <h3>6. Your Rights and Choices</h3>
    <p>As a user, you have the following rights regarding your personal data:</p>
    <ul>
      <li><strong>Access:</strong> You can request a copy of the personal data we hold about you.</li>
      <li><strong>Correction:</strong> If you believe any of your personal data is incorrect, you can request corrections.</li>
      <li><strong>Deletion:</strong> You can request that we delete your personal information, subject to certain legal limitations.</li>
      <li><strong>Opt-out:</strong> You can opt out of receiving promotional emails and newsletters from us at any time by following the unsubscribe link in our communications.</li>
    </ul>

    <h3>7. Children’s Privacy</h3>
    <p>Glowry Stone Resort does not knowingly collect or solicit personal information from children under the age of 13. If we learn that we have collected personal information from a child under 13, we will take steps to delete that information as soon as possible.</p>

    <h3>8. Changes to This Privacy Policy</h3>
    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Last Revised" date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>

    <h3>13. Contact Information</h3>
    <p>For inquiries, reservations, or assistance, please contact us at:</p>
    <ul>
      <li><strong>Phone:</strong> [+91 706666555]</li><br/>
      <li><strong>Email:</strong> [reservation@glowrystone.com
      ]</li><br/>
      <li><strong>Address:</strong> [ Glowry Stone Resort
Wadegavhan, Pune-Nagar Road,
Tal-.Parner,Dist- Ahilya Nagar (Ahmednagar).
 Maharashtra - India - 414302.]</li>
    </ul>
    <p><strong>Final Note:</strong> By using our website or services, you consent to the collection and use of your personal information as outlined in this Privacy Policy.</p>

  </div>
</section>

        </>
    )
}