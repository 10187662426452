import React from 'react'

export default function ContactSection() {
  return (
     <>
        <div className=" contact-section">
                  <h2>Glowry Stone Resort Contact Number :</h2>
                  <p className="text-black">
                    For booking inquiries or any assistance, please feel free to
                    contact us:
                  </p>
                  <p>
                    <strong className="text-black">Mobile No:</strong>{" "}
                    <a href="tel:+91706666555" className='text-white'>+91 706666555</a>
                  </p>
                  <p>
                    <strong className="text-black">Email Id:</strong>{" "}
                    <a href="  mailto:reservation@glowrystone.com" className='text-white'>
                    reservation@glowrystone.com
                    </a>
                  </p>
                  <p>
                    <strong className="text-black">Address :- </strong>{" "}
                    <p>Glowry Stone Resort
Wadegavhan,  Ahmednagar-Nagar Road,
Tal-.Parner,Dist- Ahilya Nagar (Ahmednagar).
Maharashtra - India - 414302.</p>
                  </p>
                </div>
     </>
  )
}
