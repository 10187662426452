import Slider from "react-slick";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import './Page.css';
import GallerySlider from "./GallerySlider";
import Contactbox from "./Contactbox";
import RecentPost from "./RecentPost";
import Contact from "./Contact";
import ContactSection from "./ContactSection";



export default function SchoolPicnicSpotinMumbai(){

    const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
        question: "What activities are included in the one day picnic package at Glowry Stone Resort?",
        answer: "Glowry Stone Resort offers a wide range of activities as part of the one-day picnic package, including zip-lining, ATV rides, rock climbing, boating, cycling, nature walks, team-building games, and outdoor sports. You’ll have plenty of options to keep everyone entertained!"
    },
    {
        question: "How far is Glowry Stone Resort from Mumbai?",
        answer: "Glowry Stone Resort is located just 2.5 to 3 hours away from Mumbai, making it a perfect choice for a quick getaway. Its proximity ensures that you spend more time enjoying activities and less time traveling."
    },
    {
        question: "Is the one day picnic package suitable for families?",
        answer: "Yes! Glowry Stone Resort is a family-friendly destination. The one-day picnic packages include activities suitable for all age groups, from kids’ games to adult adventure sports. Families can enjoy a day filled with fun, relaxation, and quality time together."
    },
    {
        question: "Can I book a one day picnic package for a corporate outing?",
        answer: "Absolutely! Glowry Stone Resort is a great choice for corporate day outings. Our picnic packages include team-building activities, group challenges, and workshops designed to promote collaboration and boost team morale in a relaxed and natural environment."
    },
    {
        question: "What are the meal options included in the one day picnic package?",
        answer: "The one day picnic package includes a delicious buffet with a variety of options, including vegetarian and non-vegetarian meals. You’ll also have access to snacks, refreshments, and other amenities to keep you energized throughout the day."
    },
    {
        question: "Do I need to make a reservation in advance for the one day picnic package?",
        answer: "Yes, it is highly recommended to make a reservation in advance, especially during peak seasons or weekends. This ensures that we can prepare and accommodate your group accordingly and provide you with the best experience possible."
    },
    {
        question: "What is the best time to visit Glowry Stone Resort for a one day picnic?",
        answer: "Glowry Stone Resort can be enjoyed year-round. However, the best time to visit for a one-day picnic is typically during the cooler months (October to March) when the weather is pleasant, especially for outdoor activities like trekking and nature walks. The monsoon season (June to September) is also beautiful, with lush greenery, though some activities may be limited due to rain."
    },
    {
        question: "Are there any age restrictions for the activities included in the one day picnic package?",
        answer: "Glowry Stone Resort caters to all ages. Activities like boating, cycling, and nature walks are suitable for children and adults alike. However, certain adventure activities like zip-lining or rock climbing may have specific age or health requirements. Our staff will provide guidance and ensure safety for all guests."
    },
    {
        question: "Is transportation provided for the one day picnic?",
        answer: "Currently, transportation is not included in the one-day picnic package, but we can help you with travel arrangements upon request. Many of our guests opt for carpooling or hire private transport to the resort. We are also conveniently accessible by public transport."
    },
    {
        question: "Can I customize my one day picnic package?",
        answer: "Yes! Glowry Stone Resort offers flexible and customizable picnic packages to meet your specific needs. If you have particular preferences for activities, meal options, or any special requests, our team will work with you to create a tailor-made experience for your group."
    }
];

const testimonials = [
    {
        quote: "We had an amazing time at Glowry Stone Resort for our one-day picnic. The resort is easily accessible from Mumbai, which made it perfect for a quick getaway. We were a family of five, and there was something for everyone—whether it was the kids enjoying the fun boating and cycling activities, or my husband and I having a blast on the zip-line. The picnic package was well organized, and the food was delicious—plenty of variety and all freshly prepared. The team at the resort ensured we had a smooth experience from start to finish, making it a hassle-free day. The best part was the serene lake and lush greenery, which made the experience even more relaxing. I highly recommend Glowry Stone Resort for anyone looking for a family-friendly picnic near Mumbai. We’ll definitely be coming back!",
        author: "— Mrs. Sakshi M., Mumbai"
    },
    {
        quote: "We chose Glowry Stone Resort for our company's one day picnic and couldn’t have asked for a better location. The resort is an excellent mix of adventure and relaxation, which made it ideal for both team-building activities and unwinding. Our team enjoyed treasure hunts, problem-solving challenges, and the ATV rides, which were a hit among everyone. The staff was incredibly accommodating, and the picnic package included everything we needed—delicious meals, fun activities, and plenty of time to relax. The beautiful natural surroundings and the peaceful atmosphere were a welcome break from our busy office lives. I highly recommend Glowry Stone Resort for any company looking for a corporate outing near Mumbai. It was a day well-spent, and we are already looking forward to our next trip there!",
        author: "— Mr. Rajesh K., Corporate Manager, Mumbai"
    }
];

        
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
  
   
    
  
    return(
        <>
    <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3 text-white">School Picnic Spots in Mumbai</h2>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                   <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


    {/* ========================= */}

    <section className="trending recent-articles pb-6 keywordsection">
      <div className="container">
      <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="glory stone images/Glowry Stone Resort keyword/School Picnic spot in mumbai.jpg" className="img-fluid" alt="School Picnic spot in mumbai" />
              </div>
              <h2>Glowry Stone Resort: The Ideal School Picnic Spot Near Mumbai</h2>
<p>When planning a school picnic, it's important to find a destination that offers both fun and educational activities for children, along with a safe and welcoming environment. Glowry Stone Resort, located just a few hours away from Mumbai, is one of the best school picnic spots near Mumbai. Whether you're organizing a one-day school picnic or a field trip, this resort offers an unforgettable experience for students and teachers alike.</p>

<h3>1. Picnic Spots for School Students</h3>
<p>Glowry Stone Resort is one of the best picnic spots for school students in the region. With a range of outdoor activities like nature walks, boating, zip lining, and team-building games, the resort offers an ideal environment for students to engage in learning, physical activity, and fun. The spacious grounds and safe atmosphere make it a perfect place for students to enjoy a day of relaxation and adventure.</p>

<h3>2. Picnic Spot for School Students in Ahmednagar</h3>
<p>Located in Ahmednagar, Glowry Stone Resort offers a safe, picturesque, and easily accessible location for school outings. The resort is just a short distance from Mumbai, making it a convenient option for school groups looking for a picnic spot in Ahmednagar. With an array of activities that promote teamwork, collaboration, and exploration, Glowry Stone Resort is a perfect place to take school students for a day of fun and learning.</p>

<h3>3. School Picnic Spots in Mumbai</h3>
<p>For schools looking for exciting picnic spots in Mumbai, Glowry Stone Resort provides a refreshing change of scenery. Just a few hours away from the city, the resort offers a perfect getaway for school students looking for a day full of outdoor adventures and educational activities. Whether you’re looking for team-building exercises, nature trails, or adventure sports, Glowry Stone Resort caters to all needs, making it one of the best school picnic spots near Mumbai.</p>

<h3>4. Picnic Spots Around Mumbai</h3>
<p>Glowry Stone Resort is an excellent picnic spot around Mumbai, offering a peaceful escape from the hustle and bustle of city life. Located in a serene natural setting with lush greenery, the resort offers the ideal environment for students to engage in various outdoor activities while also exploring nature. If you’re looking for a relaxing yet fun-filled day, Glowry Stone Resort is the perfect choice for an educational outing.</p>

<h3>5. Picnic Spots Near Mumbai for 2 Days</h3>
<p>For schools looking to extend their outing to two days, Glowry Stone Resort is an ideal option. The resort offers two-day picnic packages, perfect for school groups who want to combine adventure, relaxation, and educational activities over an extended stay. With comfortable accommodations, delicious meals, and an itinerary filled with fun activities like boating, treasure hunts, and nature walks, your students will have an unforgettable experience.</p>

<h3>6. 2 Days Tour Package from Mumbai</h3>
<p>Glowry Stone Resort also offers 2-day tour packages from Mumbai, making it a perfect destination for schools looking to spend a longer time outdoors. The tour package includes overnight stays, a variety of team-building activities, and plenty of outdoor adventures, all designed to offer a mix of education and enjoyment. Whether you're planning to explore the nearby natural trails or engage in outdoor games, a 2-day trip to Glowry Stone Resort will leave lasting memories for students.</p>

<h3>7. College Tour Packages Near Mumbai</h3>
<p>For college tour packages near Mumbai, Glowry Stone Resort offers a perfect mix of adventure activities and team-building opportunities. Ideal for college groups, the resort provides a refreshing environment to bond with peers, engage in treks, outdoor sports, and enjoy some leisure time in the lap of nature. The resort can customize tour packages to meet the needs of college groups, making it a great place to unwind while also indulging in adventure sports and team-building activities.</p>

<h3>Glowry Stone Resort in Pune Contact Number:</h3>
<p>Contact Glowry Stone Resort at [+91 7066665554] for more information. Glowry Stone Resort is not just a picnic spot near Ahmednagar; it’s an experience that brings families closer to nature, provides them with endless opportunities to bond, and ensures a day of relaxation, fun, and enjoyment.</p>


              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- Picnic spots for school students</p>
<p>- Picnic spot for school students in Ahmednagar</p>
<p>- School picnic spots in Mumbai</p>
<p>- Picnic spots around Mumbai</p>


                    </td>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- Picnic spots near Mumbai for 2 days</p>
<p>- 2 days tour package from Mumbai</p>
<p>- College tour packages near Mumbai</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>Why Choose Glowry Stone Resort for School Picnic Spots in Mumbai?</h2>
<p>Glowry Stone Resort is the perfect destination for school picnics near Mumbai, offering an ideal blend of adventure, education, and relaxation. Here’s why Glowry Stone Resort should be your top choice for school picnics:</p>

<h3>1. Easily Accessible from Mumbai</h3>
<p>Located just a few hours' drive from Mumbai, Glowry Stone Resort is perfectly situated for a convenient day trip or weekend outing. The proximity to the city makes it an excellent choice for school groups looking for a nearby destination that offers a blend of nature, adventure, and fun.</p>

<h3>2. Safe and Secure Environment</h3>
<p>Safety is a top priority when it comes to school outings, and Glowry Stone Resort ensures that all safety standards are met. The resort is equipped with trained staff, security personnel, and well-maintained facilities to ensure that students have a safe and enjoyable experience. Teachers and parents can relax, knowing that their children are in good hands.</p>

<h3>3. Engaging Educational Activities</h3>
<p>Glowry Stone Resort offers a range of educational activities that can enhance learning outside the classroom. Students can enjoy nature walks to learn about local flora and fauna, participate in team-building exercises that foster collaboration and leadership, or explore the natural surroundings to appreciate the environment. The resort blends fun and education seamlessly, making it an ideal spot for school picnics with an educational focus.</p>

<h3>4. Adventure-Filled Fun</h3>
<p>Glowry Stone Resort provides a wide array of adventure activities that will thrill school students. From zip lining and rappelling to boating, rock climbing, and cycling, students will have a blast while engaging in physically stimulating activities. These adventures not only offer excitement but also promote teamwork, confidence, and problem-solving skills.</p>

<h3>5. Spacious and Beautiful Natural Setting</h3>
<p>The resort is set in the lap of nature, surrounded by lush greenery, lakes, and scenic landscapes. The tranquil environment is ideal for school students to relax, unwind, and enjoy some time away from their studies. With plenty of open spaces, students can engage in group games, relax under the trees, or have a picnic by the lake, all while enjoying the beauty of the natural surroundings.</p>

<h3>6. Customizable Picnic Packages for Schools</h3>
<p>Glowry Stone Resort offers customizable picnic packages for schools, allowing teachers to plan an itinerary that suits their specific needs. Whether you're looking for a one-day picnic, a 2-day tour package, or a day filled with educational activities, the resort provides tailored packages that can include meals, transportation, and activities to ensure a smooth and enjoyable experience for everyone.</p>

<h3>7. Delicious and Nutritious Meals</h3>
<p>When it comes to school picnics, the quality of food is always a priority. Glowry Stone Resort offers delicious, nutritious meals that cater to the dietary preferences of school groups. From vegetarian to non-vegetarian options, all meals are freshly prepared and served in a hygienic environment. Special dietary requests can also be accommodated, ensuring that all students are well-fed and energized for the day’s activities.</p>

<h3>8. Team-Building Activities and Group Challenges</h3>
<p>Glowry Stone Resort excels in organizing team-building activities and group challenges that foster collaboration, communication, and leadership among students. Whether it’s through outdoor games, treasure hunts, or problem-solving activities, students learn the value of teamwork while having fun. These activities help students develop life skills that they can apply both in and outside the classroom.</p>

<h3>9. Perfect for Students of All Ages</h3>
<p>Glowry Stone Resort is ideal for school picnics with students of all ages, from younger children to teenagers. The resort offers a variety of activities that can be tailored to different age groups, ensuring that everyone, regardless of their age, will have a fun and educational experience.</p>

<h3>10. Professional and Friendly Staff</h3>
<p>The staff at Glowry Stone Resort is experienced in handling school picnics and is dedicated to making the experience as enjoyable as possible. From coordinating activities to providing assistance during group games, the team ensures that everything runs smoothly, allowing teachers and students to relax and focus on having fun.</p>



              <div className="">
                <h2>
                FAQs for Glowry Stone Resort: School Picnic Spots Near Mumbai?
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

                {/* ======================== */}
                <div className="">
                  <h2>
                  Testimonial for Glowry Stone Resort for "School Picnic Spots in Mumbai :- {" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>

                <ContactSection></ContactSection>

            </div>
          <div class="col-12 col-md-4">
            <RecentPost></RecentPost>
              <Contactbox></Contactbox>
          </div> 
          </div>
      </div>
    </section>

  <GallerySlider></GallerySlider>

        </>
    )
}  