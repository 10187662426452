import Slider from "react-slick";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import './Page.css';
import GallerySlider from "./GallerySlider";
import Contactbox from "./Contactbox";
import RecentPost from "./RecentPost";
import Contact from "./Contact";
import ContactSection from "./ContactSection";
import { Helmet } from 'react-helmet';


export default function  PicnicDestinationNearPune(){

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [

        {
          "question": "What activities are available at Glowry Stone Resort for a picnic?",
          "answer": "At Glowry Stone Resort, we offer a variety of activities for guests to enjoy, including nature walks, cricket, volleyball, boating, team-building games, and more. Whether you're here with family, friends, or colleagues, there's plenty to keep everyone entertained."
        },
        {
          "question": "How far is Glowry Stone Resort from Pune?",
          "answer": "Glowry Stone Resort is located just a short drive from Pune, making it the perfect picnic destination near Pune for a quick day trip. The resort is approximately 40-50 km away from Pune, which takes around 1 to 1.5 hours by car."
        },
        {
          "question": "Is Glowry Stone Resort suitable for family picnics?",
          "answer": "Absolutely! Our resort is family-friendly, with spacious picnic spots, safe play areas for kids, and a range of activities that are perfect for family bonding. Whether you're planning a day out with your children or a large family gathering, Glowry Stone Resort has something for everyone."
        },
        {
          "question": "Can we host a corporate outing at Glowry Stone Resort?",
          "answer": "Yes, Glowry Stone Resort is an ideal venue for corporate outings near Pune. We offer team-building activities, ample open spaces, and relaxation areas, making it perfect for team bonding exercises or a fun day out of the office."
        },
        {
          "question": "Is food available at Glowry Stone Resort?",
          "answer": "Yes, we provide a variety of food options at the resort. Our in-house restaurant offers delicious meals and snacks, and we can also customize meals based on dietary preferences or group requirements."
        },
        {
          "question": "Do we need to book in advance for a picnic at Glowry Stone Resort?",
          "answer": "While walk-ins are welcome, we recommend making a reservation in advance to ensure availability, especially during weekends or peak seasons. This helps us prepare better and ensures that you have the best experience."
        },
        {
          "question": "Are there any special offers or packages for group picnics?",
          "answer": "Yes! We offer special packages for group picnics near Pune, corporate outings, and family get-togethers. You can contact us directly to inquire about customized picnic packages that include meals, activities, and other perks."
        },
        {
          "question": "Can we bring our own food and drinks?",
          "answer": "We recommend trying our in-house meals for the best experience, but guests can bring their own snacks and drinks as long as they adhere to our guidelines and respect the resort’s rules. However, alcoholic beverages are only allowed in designated areas."
        },
        {
          "question": "What is the best time to visit Glowry Stone Resort for a picnic?",
          "answer": "The resort is open year-round, but the best time to visit is during the cooler months (October to February), especially for outdoor activities. You can also enjoy a relaxing picnic in the summer with the comfort of shaded spots and cooling refreshments."
        },
        {
          "question": "Are there accommodations available if we decide to stay overnight?",
          "answer": "Yes, Glowry Stone Resort offers comfortable one-day stay options for guests who want to extend their visit. Whether it's a weekend getaway or a mid-week retreat, you can stay in our cozy rooms or cottages and enjoy a relaxing evening surrounded by nature."
        }
      
      
  ];
  
      const testimonials = [
        
            {
              "quote": "Glowry Stone Resort was the perfect getaway for our family picnic! We were looking for a peaceful and fun-filled day trip near Pune, and this resort exceeded our expectations. The location is beautiful, surrounded by nature, and the resort offers so many activities like boating, cricket, and volleyball. Our kids loved the play areas, and the picnic spots were well-maintained and spacious. The staff was friendly, and the food was delicious. It's a fantastic place to relax and bond with family. We highly recommend it for anyone looking for a one-day trip near Pune!",
              "author": "- Ravi & Priya Sharma, Pune"
            },
            {
              "quote": "We chose Glowry Stone Resort for a day outing with our friends, and it was an amazing experience! The resort is just the right distance from Pune, making it an easy drive for a quick escape from the city. We loved the wide variety of activities available, especially the team-building games, which were perfect for our group. The resort offers a great mix of adventure and relaxation, and the meals were outstanding. Whether you're planning a family picnic or a fun outing with friends, Glowry Stone Resort is a top choice!",
              "author": "- Sandeep & Neha Gupta, Mumbai"
            }
        
          
        ];
  
        
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
  
    const hotelImages = [
      {
        place: "Hotel-Images/Hotel In Viman Nagar.jpg",
        text: "Hotel In Viman Nagar",
        path:'hotel-in-viman-nagar'
      },
      {
        place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
        text: "Hotels in Viman Nagar for Stay",
        path:'viman-nagar-hotels-for-stay'
      },
      {
        place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
        text: "Lodge in Viman Nagar Pune",
        path:'lodge-in-viman-nagar-pune'
      },
      {
        place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
        text: "Hotels Near Viman Nagar Airport Pune",
        path:'hotels-near-viman-nagar-airport-pune'
      },
      {
        place: "Hotel-Images/Pune Airport Hotel.jpg",
        text: "Pune Airport Hotel",
        path:'pune-airport-hotel'
      },
      {
        place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
        text: "Hotel in Wadgaon Sheri",
        path:'hotel-wadgaon-sheri'
      },
      {
        place: "Hotel-Images/Online hotel booking in pune.jpg",
        text: "Online Hotel Booking in Pune",
        path:'online-hotel-booking-pune'
      },
      {
        place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
        text: "Hotel For Stay in Lohegaon Airport",
        path:'hotel-for-stay-lohegaon-airport'
      }
  
    
    ];


    const schemaData = {
      "@context": "https://schema.org",
      "@type": "Resort",
      "name": "Glowry Stone Resort",
      "image": "https://www.glowrystone.com/images/one-day-resort.jpg",
      "description": "Glowry Stone Resort offers the best one-day picnic spots and resorts near Pune. Ideal for family outings, corporate team outings, and weekend getaways.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road",
        "addressLocality": "Parner",
        "addressRegion": "Maharashtra",
        "postalCode": "414302",
        "addressCountry": "IN"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 7066665554",
        "contactType": "Customer Service",
        "areaServed": "IN",
        "availableLanguage": "English"
      },
      "sameAs": [
        "https://www.facebook.com/glowrystone",
        "https://www.instagram.com/glowrystone"
      ],
      "url": "https://www.glowrystone.com"
    };
  

    
  
    return(
        <>

<Helmet>
        {/* Title */}
        <title>
          Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort | Best One Day Picnic & Stay Resorts Near Pune | Corporate Outing & Family Getaways
        </title>

        {/* Meta Tags */}
        <meta
          name="description"
          content="Looking for the best one-day resorts near Pune? Glowry Stone Resort offers picnic spots, family-friendly outings, corporate team-building activities, and luxury one-day stay resorts near Pune."
        />
        <meta
          name="keywords"
          content="picnic destination near Pune, one day picnic spot near Pune, one day resort near Pune for family, one day picnic resorts near Pune, resorts for corporate outing near Pune, one day trip near Pune resort, best resorts near Pune for one day trip, day picnic resorts near Pune, one day outing resorts near Pune, picnic spots near Pune"
        />
        <meta name="author" content="Glowry Stone Resort" />
        <meta name="robots" content="index, follow" />

        {/* Open Graph for Social Sharing */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Glowry Stone Resort - Best One Day Picnic Spots & Resorts Near Pune | Family & Corporate Outings"
        />
        <meta
          property="og:description"
          content="Explore the best one-day resorts near Pune for family getaways, team outings, and relaxing day trips. Glowry Stone Resort offers picnic spots with fun activities for everyone."
        />
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" />
        <meta property="og:site_name" content="Glowry Stone Resort" />

        {/* Contact Information */}
        <meta
          name="contact"
          content="For reservations, please contact Glowry Stone Resort at +91 7066665554 or +91 9145557771. For inquiries, email us at reservation@glowrystone.com."
        />
        <meta
          name="address"
          content="Glowry Stone Resort, Wadegavhan, Ahmednagar-Nagar Road, Tal. Parner, Dist. Ahilya Nagar (Ahmednagar), Maharashtra - 414302, India"
        />
        <meta name="email" content="reservation@glowrystone.com" />
        <meta name="phone" content="+91 7066665554, +91 9145557771" />

        {/* Canonical Link */}
        <link rel="canonical" href="https://www.glowrystone.com" />

        {/* JSON-LD Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>
    <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3  text-white">Picnic Destination Near Pune</h2>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                   <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


    {/* ========================= */}

    <section className="trending recent-articles pb-6 keywordsection">
      <div className="container">
      <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="glory stone images/Glowry Stone Resort keyword/Picnic destination near pune.jpg" className="img-fluid" alt="Picnic destination near pune" />
              </div>
              <h2>Glowry Stone Resort: The Ultimate Picnic Destination Near Pune</h2>
<p>Glowry Stone Resort is your answer! Nestled amidst lush greenery and serene landscapes, this beautiful resort offers the ideal getaway for families, couples, and groups seeking a refreshing escape from the hustle and bustle of city life. Whether you're planning a one-day outing, a weekend retreat, or a corporate picnic, Glowry Stone Resort offers a range of activities, picturesque views, and exceptional hospitality to make your trip memorable.</p>

<h3>1. Perfect One-Day Picnic Spot Near Pune</h3>
<p>Glowry Stone Resort is one of the best picnic destinations near Pune, just a short drive away from the city. Our tranquil surroundings and beautiful outdoor spaces provide the perfect setting for a one-day picnic spot near Pune. Enjoy the fresh air, scenic views, and plenty of open spaces where you can relax, unwind, and enjoy your day with friends and family.</p>
<ul>
  <li><strong>Secluded Picnic Areas:</strong> Choose from a variety of serene, private picnic spots.</li>
  <li><strong>Lush Greenery:</strong> Perfect for a peaceful retreat or a day of outdoor games and relaxation.</li>
</ul>

<h3>2. Ideal One-Day Resort Near Pune for Families</h3>
<p>If you're looking for a one-day resort near Pune for family, Glowry Stone Resort offers the perfect destination. With family-friendly accommodations, spacious lawns, and activities suited for all ages, we ensure that every family member has a great time. Whether it's enjoying a picnic by the lake or participating in outdoor games, Glowry Stone Resort provides an exciting and memorable experience for families.</p>
<ul>
  <li><strong>Family-Friendly Activities:</strong> Enjoy nature walks, cricket, volleyball, and other group activities.</li>
  <li><strong>Comfortable Facilities:</strong> Safe, clean, and spacious facilities for families to enjoy a relaxing day.</li>
</ul>

<h3>3. One-Day Picnic Resorts Near Pune</h3>
<p>Glowry Stone Resort is not just a place for a picnic—it's a full experience. As one of the best one-day picnic resorts near Pune, we offer a range of activities to keep you entertained throughout the day. Whether you are planning a group outing or a peaceful solo retreat, our resort offers everything you need for a perfect day trip near Pune.</p>
<ul>
  <li><strong>Exciting Outdoor Games:</strong> Cricket, volleyball, badminton, and more!</li>
  <li><strong>Nature Exploration:</strong> Explore our lush grounds through guided walks and enjoy the beautiful landscapes.</li>
</ul>

<h3>4. One-Day Stay Resort Near Pune</h3>
<p>If you'd like to make your picnic or outing a little longer, we offer an ideal one-day stay resort near Pune. Stay with us overnight and experience a restful night in our comfortable rooms, surrounded by nature. Whether it's a family retreat or a couple’s getaway, Glowry Stone Resort provides you with a peaceful environment to recharge for the next day of activities.</p>
<ul>
  <li><strong>Comfortable Rooms:</strong> Relax in our cozy, well-maintained rooms.</li>
  <li><strong>Tranquil Atmosphere:</strong> A peaceful environment to escape from city life.</li>
</ul>

<h3>5. One-Day Picnic Resorts with Activities Near Pune</h3>
<p>At Glowry Stone Resort, we believe that a one-day picnic resort near Pune should be more than just a place to relax—it should be a place for fun and adventure. We offer a wide variety of activities for all ages, including outdoor games, nature walks, and boating, ensuring that your trip is filled with excitement and enjoyment.</p>
<ul>
  <li><strong>Outdoor Sports:</strong> Cricket, volleyball, badminton, and more.</li>
  <li><strong>Nature Walks and Trails:</strong> Enjoy the surrounding flora and fauna through guided walks.</li>
  <li><strong>Boating:</strong> Enjoy a peaceful boat ride on our serene lake.</li>
</ul>

<h3>6. Resorts for Corporate Outing Near Pune</h3>
<p>Looking for the perfect place for a corporate outing near Pune? Glowry Stone Resort offers facilities for team-building activities, workshops, and group games, making it an excellent choice for corporate events. Whether you need a place for meetings or a venue to foster collaboration and bonding, Glowry Stone Resort provides a well-rounded experience for corporate groups.</p>
<ul>
  <li><strong>Team-Building Activities:</strong> Boost team morale and improve communication with our group exercises.</li>
  <li><strong>Meeting Spaces:</strong> Organize meetings or workshops in a peaceful and natural environment.</li>
  <li><strong>Catering Options:</strong> Delicious and customizable meal options for corporate groups.</li>
</ul>

<h3>7. One-Day Trip Resort Near Pune</h3>
<p>Glowry Stone Resort is an ideal destination for a one-day trip near Pune, offering a peaceful and relaxing environment to escape the city's hustle and bustle. Whether you're coming for a family picnic, a romantic getaway, or a group outing, we provide all the amenities and activities you need for a fulfilling day trip.</p>
<ul>
  <li><strong>Easy Accessibility:</strong> Located just a short distance from Pune, making it the ideal destination for a quick getaway.</li>
  <li><strong>Variety of Activities:</strong> A wide range of activities for all age groups, from kids to adults.</li>
</ul>

<h3>8. Resorts Near Pune for One-Day Trip</h3>
<p>If you're looking for a resort near Pune for a one-day trip, Glowry Stone Resort is the perfect choice. We provide an all-inclusive experience with fun activities, picturesque views, and excellent hospitality. Spend the day outdoors, exploring nature, enjoying meals, and engaging in team-building or recreational activities.</p>
<ul>
  <li><strong>Group Activities:</strong> Perfect for family and friends to bond over fun group activities.</li>
  <li><strong>Peaceful Environment:</strong> A calming setting for individuals, couples, or families to unwind and relax.</li>
</ul>

<h2>Glowry Stone Resort: The Ultimate Destination for Your One-Day Trip Near Pune</h2>
<p>Glowry Stone Resort offers the best of both worlds: a relaxing atmosphere combined with a variety of activities to keep you entertained. Whether you're coming for a family picnic, a corporate outing, or a romantic retreat, our resort is the perfect choice for an unforgettable day trip near Pune. From one-day stays to exciting outdoor activities, we cater to all types of guests, ensuring that your visit is special.</p>



<h2>Book Your One-Day Trip Today!</h2>
<p>Contact us today to book your one-day picnic resort near Pune experience at Glowry Stone Resort. Escape the stress of the city and enjoy a day of fun, relaxation, and bonding with loved ones or colleagues. With customizable packages and a range of activities, we are committed to providing you with an unforgettable experience.</p>

<h3>Best Resort for Family Picnic Near Pune</h3>
<p>For families looking for a one-day picnic resort near Pune, Glowry Stone Resort provides the perfect setting. With ample space for outdoor games, picnic tables, and tranquil areas to relax, your family can enjoy the day together in nature. The peaceful surroundings, coupled with activities like nature walks, badminton, and cricket, ensure that everyone has a memorable experience.</p>
<ul>
  <li><strong>Family-friendly facilities:</strong> Safe and spacious environment for families with kids.</li>
  <li><strong>Outdoor activities:</strong> Nature walks, games, and more to keep the family entertained.</li>
</ul>

<h3>Ideal One-Day Resort Near Pune for Group Outings</h3>
<p>Glowry Stone Resort is also one of the best resorts near Pune for one-day trip with friends or colleagues. Offering ample space for team-building activities, group games, and outdoor picnics, it's an ideal choice for large groups looking for a place to bond and relax. The resort is perfect for corporate outings near Pune, where you can combine fun with work in a natural, peaceful environment.</p>
<ul>
  <li><strong>Group activities:</strong> Team-building exercises, cricket, volleyball, and outdoor group games.</li>
  <li><strong>Spacious lawns:</strong> Perfect for group picnics or corporate events.</li>
</ul>

<h3>One-Day Stay Resorts Near Pune</h3>
<p>If you want to extend your trip, Glowry Stone Resort offers the option for a one-day stay resort near Pune. Enjoy a restful night in our cozy rooms, surrounded by the beauty of nature. Whether it's a family getaway or a romantic retreat, a stay at our resort promises a relaxing experience.</p>
<ul>
  <li><strong>Comfortable rooms:</strong> Spacious and well-furnished rooms for a peaceful night.</li>
  <li><strong>Peaceful surroundings:</strong> Escape the city's hustle and unwind in a tranquil environment.</li>
</ul>

<h3>Day Resorts Near Pune with Activities</h3>
<p>If you’re looking for day resorts near Pune that offer fun and engaging activities, Glowry Stone Resort is the perfect choice. We provide a variety of outdoor activities, such as nature walks, boating, and sports, allowing you to spend your day in the great outdoors. Whether you're in a group or with your family, there’s something for everyone to enjoy.</p>
<ul>
  <li><strong>Outdoor activities:</strong> Cricket, volleyball, nature trails, and more.</li>
  <li><strong>Boating experience:</strong> Enjoy a relaxing boat ride in our serene surroundings.</li>
</ul>

<h3>Best One-Day Picnic Resorts with Beautiful Scenery</h3>
<p>Glowry Stone Resort is the ideal picnic spot near Pune, offering stunning landscapes and scenic beauty. Whether it's a peaceful family day or a fun day out with friends, the lush greenery and picturesque views create the perfect backdrop for your picnic.</p>
<ul>
  <li><strong>Tranquil atmosphere:</strong> Lush lawns and scenic views make it the perfect picnic spot.</li>
  <li><strong>Variety of picnic spots:</strong> Find the perfect spot for your family or group outing.</li>
</ul>

<h3>Perfect One-Day Picnic Resort Near Pune for Couples</h3>
<p>If you’re looking for a couple picnic spot near Pune, Glowry Stone Resort offers a serene and romantic environment to relax and enjoy each other's company. With quiet spots, nature walks, and a peaceful atmosphere, this resort provides a peaceful escape for couples looking to spend quality time together.</p>
<ul>
  <li><strong>Romantic settings:</strong> Enjoy a cozy picnic or peaceful walks with your partner.</li>
  <li><strong>Private spaces:</strong> Ideal for intimate picnics and relaxation.</li>
</ul>

<h3>Glowry Stone Resort in Pune Contact Number:</h3>
<p>Contact Glowry Stone Resort at <strong>[+91 7066665554]</strong> for more information or to book your visit. Glowry Stone Resort is not just a picnic spot near Ahmednagar; it’s an experience that brings families closer to nature, provides them with endless opportunities to bond, and ensures a day of relaxation, fun, and enjoyment.</p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- Picnic destination near Pune</p>
<p>- One day picnic spot near Pune</p>
<p>- One day resort near Pune</p>
<p>- One day resort near Pune for family</p>
<p>- One day picnic resorts near Pune</p>
<p>- One day stay resort near Pune</p>
<p>- One day picnic resorts with activities near Pune</p>
<p>- Resorts for corporate outing near Pune</p>
<p>- One day trip near Pune resort</p>
<p>- Resorts near Pune for one day trip</p>
<p>- 1 day resort near Pune</p>
<p>- One day trip resort near Pune</p>


                    </td>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- 1 day outing resorts near Pune</p>
<p>- One day picnic resorts near Pune for family</p>
<p>- 1 day picnic resort near Pune</p>
<p>- Best resorts near Pune for one day trip</p>
<p>- Day picnic resorts near Pune</p>
<p>- Day resorts near Pune</p>
<p>- One day outing resorts near Pune</p>
<p>- One day resort in Pune</p>
<p>- One day trip destination near Pune</p>
<p>- One day stay resorts near Pune</p>
<p>- Picnic spot in Pune</p>
<p>- Picnic spots near Pune</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>Why Choose Glowry Stone Resort for Your Picnic Destination Near Pune?</h2>
<p>Glowry Stone Resort is the perfect picnic destination near Pune, offering an ideal blend of natural beauty, relaxation, and exciting activities. Here's why you should choose us for your next getaway:</p>

<ol>
  <li><strong>Scenic Location:</strong> Nestled in the heart of nature, our resort is surrounded by lush greenery, making it a tranquil escape from the hustle and bustle of city life. The picturesque views create the perfect backdrop for your family or group picnic.</li>
  
  <li><strong>Wide Range of Activities:</strong> Whether you're looking for outdoor adventures or relaxing moments, Glowry Stone Resort offers something for everyone. Enjoy nature walks, cricket, volleyball, boating, and more to keep you engaged throughout the day.</li>
  
  <li><strong>Perfect for Families and Groups:</strong> Whether you're planning a family picnic or a corporate outing near Pune, our spacious resort offers ample space and activities for both relaxation and team-building exercises.</li>
  
  <li><strong>Convenient Location:</strong> Located just a short drive from Pune, Glowry Stone Resort is easily accessible for a quick one-day trip. It’s the ideal place for a weekend getaway or a mid-week picnic.</li>
  
  <li><strong>Comfortable Facilities:</strong> We offer comfortable, clean, and well-maintained facilities, including a play area for kids, picnic spots, and relaxing spots where you can unwind and enjoy the surroundings.</li>
  
  <li><strong>Customized Packages:</strong> We understand that every guest has unique needs, so we offer customized picnic and outing packages to suit families, couples, and corporate groups alike.</li>
  
  <li><strong>Safe and Family-Friendly:</strong> The resort is designed to be family-friendly, with secure spaces, safety measures, and activities suitable for guests of all ages. Your kids can enjoy their time while you relax and explore.</li>
  
  <li><strong>Affordable Pricing:</strong> We provide an affordable option for a quality one-day outing near Pune, ensuring a memorable and budget-friendly experience for all.</li>
</ol>



              <div className="">
                <h2>
                Frequently Asked Questions for Glowry Stone Resort - Picnic Destination Near Pune ? 
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

                {/* ======================== */}
                <div className="">
                  <h2>
                  Testimonials " Glowry Stone Resort" for Picnic Destination Near Pune:-{" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>
                <ContactSection></ContactSection>

</div>
<div class="col-12 col-md-4">
<RecentPost></RecentPost>
  <Contactbox></Contactbox>
</div> 
</div>
         
      </div>
    </section>

  <GallerySlider></GallerySlider>

        </>
    )
}  