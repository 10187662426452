import Slider from "react-slick";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import MenuSection from './MenuSection';
import './App.css';



export default function Menu(){
    const menuData = {
        vegStarters: [
          "Roasted Papad", "Fry Papad", "Masala Papad", "Gulabjamun Dish", 
          "Finger Chips", "Soyabean Chilli", "Paneer Chilli", "Veg Manchurian", "Veg Crispy"
        ],
        vegSoups: [
          "Veg Manchow Soup", "Hot And Sour Solt Soup", "Veg Clear Soup", "Tomato Soup"
        ],
        vegMainCourse: [
          "Dal Tadaka", "Chana Masala", "Shev Bhaji", "Soyabean Masala", "Ghee Methi Masala",
          "Paneer Masala", "Palak Paneer", "Veg Maratha", "Mix Veg", "Paneer Kadhai", "Paneer Kolhapuri",
          "Paneer Kaju Masala", "Kaju Masala", "Paneer Tikka Masala", "Paneer Butter Masala", 
          "Paneer Tava", "Paneer Chingari", "Muttar Paneer Masala", "Paneer Bhurji", "Paneer Aangara",
          "Paneer Tufani", "Paneer Maharaja", "Paneer Malai Kofta", "Dal fry", "Green Peas Masala",
          "Bhendi Fry/Masala", "Aalu Mutter", "Matki Fry", "Veg Kolhapuri", "Mix Veg", "Kaju Kari", 
          "Veg Tava"
        ],
        handiSpecial: [
          "Paneer Handi F/H", "Kaju Handi F/H", "Veg Handi F/H", "Dal Tadaka Handi F/H", 
          "Aalu Matar Handi F/H", "Green Peas Handi F/H", "Shevbhaji Handi F/H", "Mutter Paneer Handi F/H",
          "Kaju Paneer Handi F/H", "Veg Kolhapuri Handi F/H", "Veg Maratha Handi F/H", "Paneer Butter Handi F/H"
        ],
        specialVegKalaMasala: [
          "Shev Bhaji", "Matki Masala", "Green Peas Masala", "Chana Masala", "Mix Veg", 
          "Veg Maratha", "Mutter Paneer", "Paneer Masala"
        ]
      };
      
  
    return(
        <>
    <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3 text-white">Special Menu at Glowry Stone Resort</h2>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


    {/* ========================= */}


    <div className="menu-container">
      <MenuSection title="Veg Starters" items={menuData.vegStarters} />
      <MenuSection title="Veg Soups" items={menuData.vegSoups} />
      <MenuSection title="Veg Main Course" items={menuData.vegMainCourse} />
      <MenuSection title="Handi Special" items={menuData.handiSpecial} />
      <MenuSection title="Special Veg Kala Masala" items={menuData.specialVegKalaMasala} />
    </div>
    
        </>
    )
}  