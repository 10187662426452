import './App.css';
import './css/bootstrap.min.css';
import './css/plugin.css';
import './css/style.css';
import Header from './Header';
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Home';
import About from './About';
import Policy from './Policy';
import FAQs from './FAQs';
import Terms from './Terms';
import Page from './Page';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Gallery from './Gallery';
import Enquiry from './Enquiry';
import Banner from './Banner';
import Contact from './Contact';
import Attraction from './Attraction';
import Activities from './Activities';
import NearPlaces from './NearPlaces';
import Package from './Package';
import OnedayPicnicSpot from './OnedayPicnicSpot';
import PicnicSpotNearAhemadnagar from './PicnicSpotNearAhemadnagar';
import PicnicDestinationNearPune from './PicnicDestinationNearPune';
import CorporateOutingResortnearAhemdnagar from './CorporateOutingResortnearAhemdnagar';
import GlowryStoneResortNearPune from './GlowryStoneResortNearPune';
import DestinationWeddingNearAhmednagar from './DestinationWeddingNearAhmednagar';
import HurdapartyplacesnearAhmednagar from './HurdapartyplacesnearAhmednagar';
import WaterparknearAhmednagar from './WaterparknearAhmednagar';
import SchoolPicnicSpotnearAhmednagar from './SchoolPicnicSpotnearAhmednagar';
import OnedayAdventuresResortNearAhmednagar from './OnedayAdventuresResortNearAhmednagar';
import ResortNearPune from './ResortNearPune';
import ForestResortNearAhmednagar from './ForestResortNearAhmednagar';
import NaturalResortnearAhmednagarforweekend from './NaturalResortnearAhmednagarforweekend';
import MangoMahostavinpune from './MangoMahostavinpune';
import CampingPlacesNearAhmednagar from './CampingPlacesNearAhmednagar';
import ActivitiesResortNearPune from './ActivitiesResortNearPune';
import GlowryStoneResortNeraMumbaiForfamily from './GlowryStoneResortNeraMumbaiForfamily';
import OnedayPicnicSpotinMumbai from './OnedayPicnicSpotinMumbai';
import Onedaypicnicpackagenearmumbai from './OnedayPicnicpackagenearmumbai';
import SchoolPicnicSpotinMumbai from './SchoolPicnicSpotinMumbai';
import ResortNearMumbai from './ResortNearMumbai';
import Teamoutingplacesnearmumbai from './Teamoutingplacesnearmumbai';
import CorporateoutingNearMumbai from './CorporateoutingNearMumbai';
import PicnicSpotNearNashik from './PicnicSpotNearNashik';
import FarmhouseonrentinAhmednagar from './FarmhouseonrentinAhmednagar';
import OnedayPicnicSpotInpuneforfamily from './OnedayPicnicSpotInpuneforfamily';
import Onedaytripnearpune from './Onedaytripnearpune';
import PicnicSpotnearpune from './PicnicSpotnearpune';
import CorporateOutingresortnearpune from './CorporateOutingresortnearpune';
import DestinationWeddingnearpune from './DestinationWeddingnearpune';
import HurdaPartyplacesnearpune from './HurdaPartyplacesnearpune';
import Waterparknearpune from './Waterparknearpune';
import SchoolPicnicSpotnearpune  from './SchoolPicnicSpotnearpune';
import OnedayAdventureResortNearPune from './OnedayAdventureResortNearpune';
import ForestResortNearPune from './ForestResortNearPune';
import NaturalResortNearPuneforweekend from './NaturalResortNearPuneforweekend';
import Campingplacesnearpune from './Campingplacesnearpune';
import Farmhouseonrentinpune from './Farmhouseonrentinpune';
import Refund from './Refund';
import AccomodationNearSupainMidc from './AccomodationNearSupainMidc';
import Menu from './Menu';
function App() {
  return (
   <>
      <Router>
            <ScrollToTop></ScrollToTop>
            <Header/>
       <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/policy" element={<Policy />}></Route>
        <Route path='/faq' element={<FAQs></FAQs>}></Route>
        <Route path='/terms' element={<Terms></Terms>}></Route>
        <Route path='/page' element={<Page></Page>}></Route>
        <Route path='/gallery' element={<Gallery></Gallery>}></Route>
        <Route path='/enquiry' element={<Enquiry></Enquiry>}></Route>
        <Route path="/contact" element={<Contact></Contact>}></Route>
        <Route path="/attraction" element={<Attraction></Attraction>}></Route>
        <Route path="/activities" element={<Activities></Activities>}></Route>
        <Route path="/nearPlaces" element={<NearPlaces></NearPlaces>}></Route>
        <Route path="/package" element={<Package></Package>}></Route>
        <Route path="/One-Day-Picnic-Spot-Near-Ahmednagar-for-Family" element={<OnedayPicnicSpot></OnedayPicnicSpot>}></Route>
        <Route path="/One-Day-Trip-Near-Ahmednagar" element={<OnedayPicnicSpot></OnedayPicnicSpot>}></Route>
        <Route path="/Picnic-Spot-Near-Ahmednagar" element={<PicnicSpotNearAhemadnagar></PicnicSpotNearAhemadnagar>}></Route>
        <Route path="/Picnic-Destination-Near-Pune" element={<PicnicDestinationNearPune></PicnicDestinationNearPune>}></Route>
        <Route path="/Corporate-Outing-Resorts-Near-Ahmednagar" element={<CorporateOutingResortnearAhemdnagar></CorporateOutingResortnearAhemdnagar>}></Route>
        <Route path="/Glowry-Stone-Resort-Near-Pune" element={<GlowryStoneResortNearPune></GlowryStoneResortNearPune>}></Route>
        <Route path="/Destination-Wedding-Near-Ahmednagar" element={<DestinationWeddingNearAhmednagar></DestinationWeddingNearAhmednagar>}></Route>
        <Route path='/Hurda-Party-Places-Near-Ahmednagar' element={<HurdapartyplacesnearAhmednagar></HurdapartyplacesnearAhmednagar>}></Route>
        <Route path="/Water-Park-Near-Ahmednagar" element={<WaterparknearAhmednagar></WaterparknearAhmednagar>}></Route>
        <Route path="/School-Picnic-Spots-Near-Ahmednagar-for-Students" element={<SchoolPicnicSpotnearAhmednagar></SchoolPicnicSpotnearAhmednagar>}></Route>
        <Route path="/One-Day-Adventure-Resorts-Near-Ahmednagar" element={<OnedayAdventuresResortNearAhmednagar></OnedayAdventuresResortNearAhmednagar>}></Route>
        <Route path='/Resort-Near-Pune' element={<ResortNearPune></ResortNearPune>}></Route>
        <Route path='/Forest-Resorts-Near-Ahmednagar' element={<ForestResortNearAhmednagar></ForestResortNearAhmednagar>}></Route>
        <Route path='/Nature-resorts-near-Ahmednagar-for-weekend' element={<NaturalResortnearAhmednagarforweekend></NaturalResortnearAhmednagarforweekend>}></Route>
        <Route path='/Mango-Mahotsav-in-Ahmednagar' element={<MangoMahostavinpune></MangoMahostavinpune>}></Route>
        <Route path='/Camping-Places-Near-Ahmednagar' element={<CampingPlacesNearAhmednagar></CampingPlacesNearAhmednagar>}></Route>
        <Route path='/Activities-Resort-Near-Pune' element={<ActivitiesResortNearPune></ActivitiesResortNearPune>}></Route>
        <Route path='/Glowry-Stone-resort-Near-Mumbai-for-Family' element={<GlowryStoneResortNeraMumbaiForfamily></GlowryStoneResortNeraMumbaiForfamily>}></Route>
        <Route path='/One-Day-Picnic-Spot-From-Mumbai' element={<OnedayPicnicSpotinMumbai></OnedayPicnicSpotinMumbai>}></Route>
        <Route path='/One-Day-Picnic-Packages-Near-Mumbai' element={<Onedaypicnicpackagenearmumbai></Onedaypicnicpackagenearmumbai>}></Route>
        <Route path='/School-Picnic-Spots-in-Mumbai' element={<SchoolPicnicSpotinMumbai></SchoolPicnicSpotinMumbai>}></Route>
        <Route path='/Resort-Near-Mumbai' element={<ResortNearMumbai></ResortNearMumbai>}></Route>
        <Route path='/Team-Outing-Places-Near-Mumbai' element={<Teamoutingplacesnearmumbai></Teamoutingplacesnearmumbai>}></Route>
        <Route path='/Corporate-Outing-Near-Mumbai' element={<CorporateoutingNearMumbai></CorporateoutingNearMumbai>}></Route>
        <Route path='/Picnic-Spot-Near-Nashik' element={<PicnicSpotNearNashik></PicnicSpotNearNashik>}></Route>
       <Route path='/Farm-House-On-Rent-in-Ahmednagar' element={<FarmhouseonrentinAhmednagar></FarmhouseonrentinAhmednagar>}></Route>
       <Route path='/One-Day-Picnic-Spot-Near-Pune-for-Family' element={<OnedayPicnicSpotInpuneforfamily></OnedayPicnicSpotInpuneforfamily>}></Route>
       <Route path='/One-Day-Trip-Near-Pune' element={<Onedaytripnearpune></Onedaytripnearpune>}></Route>
       <Route path='/Picnic-Spot-Near-Pune' element={<PicnicSpotnearpune></PicnicSpotnearpune>}></Route>
       <Route path='/Corporate-Outing-Resorts-Near-Pune' element={<CorporateOutingresortnearpune></CorporateOutingresortnearpune>}></Route>
       <Route path='/Destination-Wedding-Near-Pune' element={<DestinationWeddingnearpune></DestinationWeddingnearpune>}></Route>
       <Route path='/Hurda-Party-Places-Near-Pune' element={<HurdaPartyplacesnearpune></HurdaPartyplacesnearpune>}></Route>
       <Route path='/Water-Park-Near-Pune' element={<Waterparknearpune></Waterparknearpune>}></Route>
       <Route path='/School-Picnic-Spots-Near-Pune-for-Students' element={<SchoolPicnicSpotnearpune></SchoolPicnicSpotnearpune>}></Route>
      <Route path='/One-Day-Adventure-Resorts-Near-Pune' element={<OnedayAdventureResortNearPune></OnedayAdventureResortNearPune>}></Route>
      <Route path='/Forest-Resorts-Near-Pune' element={<ForestResortNearPune></ForestResortNearPune>}></Route>
      <Route path='/Nature-resorts-near-pune-for-weekend' element={<NaturalResortNearPuneforweekend></NaturalResortNearPuneforweekend>}></Route>
      <Route path='/Camping-Places-Near-Pune' element={<Campingplacesnearpune></Campingplacesnearpune>}></Route>
       <Route path='/Farm-House-On-Rent-in-Pune' element={<Farmhouseonrentinpune></Farmhouseonrentinpune>}></Route>
       <Route path='/refund' element={<Refund></Refund>}></Route>
       <Route path='/Accommodation-Near-Supa-in-Midc' element={<AccomodationNearSupainMidc></AccomodationNearSupainMidc>}></Route>
       <Route path='menucard' element={<Menu></Menu>}></Route>
      </Routes>

      <Footer/>
      </Router>
   </>
  );
}

export default App;
