import React from 'react';
import { Link } from "react-router-dom";

export default function RecentPost() {

    const hotelImages = [
        {
          place: "glory stone images/Glowry Stone Resort keyword/Accomodation near supa in midc.jpg",
          text: "Accomodation near supa in MIDC",
          path:'/Accommodation-Near-Supa-in-Midc'
        },
        {
          place: "glory stone images/Glowry Stone Resort keyword/Mango Mohostav in Ahmednagar.jpg",
          text: "Mango Mohostav in Ahmednagar",
          path:'/Mango-Mahotsav-in-Ahmednagar'
        },
        {
          place: "glory stone images/Glowry Stone Resort keyword/Hurda Party place near Ahmednagar.jpg",
          text: "Hurda Party place near Ahmednagar",
          path:'/Hurda-Party-Places-Near-Ahmednagar'
        },
        {
          place: "glory stone images/Glowry Stone Resort keyword/picnic spot near Ahmednagar.jpg",
          text: "Picnic spot near Ahmednagar",
          path:'/Picnic-Spot-Near-Ahmednagar'
        },
        {
          place: "glory stone images/Glowry Stone Resort keyword/Farm house on rent in Ahmednagar.jpg",
          text: "Farm house on rent in Ahmednagar",
          path:'/Farm-House-On-Rent-in-Ahmednagar'
        },
        {
          place:"glory stone images/Glowry Stone Resort keyword/Glowry Stone Resort Near mumbai for family.jpg",
          text: "Glowry Stone Resort Near mumbai for family",
          path:'/Glowry-Stone-resort-Near-Mumbai-for-Family'
        },
        {
          place: "glory stone images/Glowry Stone Resort keyword/Activities Resort Near Pune.jpg",
          text: "Activities Resort Near Pune",
          path:'/Activities-Resort-Near-Pune'
        },
        {
          place: "glory stone images/Glowry Stone Resort keyword/Camping places near Ahmednagar.jpg",
          text: "Camping places near Ahmednagar",
          path:'/Camping-Places-Near-Ahmednagar'
        }
    
      
      ];
  
  return (
   <>
      <div>
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <Link to={e.path}>{e.text}</Link>
                      </div>
                    </a>
                  </div>
                );
              })}
              </div>
   </>
  )
}
