import React, { useState,useEffect } from "react";
import { useRef } from 'react';
import { Link } from 'react-router-dom';  
import Testimonial from './Testimonial';
import { Helmet } from "react-helmet";



export default function About(){

    
    const [experience, setExperience] = useState(0);
    const [packages, setPackages] = useState(0);
    const [customers, setCustomers] = useState(0);
    const [awards, setAwards] = useState(0);
  
    useEffect(() => {
      // Counter animation logic
      const experienceInterval = setInterval(() => {
        if (experience < 20) setExperience(prev => prev + 1);
      }, 20);
  
      const packagesInterval = setInterval(() => {
        if (packages < 100) setPackages(prev => prev + 1);
      }, 20);
  
      const customersInterval = setInterval(() => {
        if (customers < 700) setCustomers(prev => prev + 1);
      }, 20);
  
      const awardsInterval = setInterval(() => {
        if (awards < 5) setAwards(prev => prev + 1);
      },20);
  
      // Cleanup intervals
      return () => {
        clearInterval(experienceInterval);
        clearInterval(packagesInterval);
        clearInterval(customersInterval);
        clearInterval(awardsInterval);
      };
    }, [experience, packages, customers, awards]);
  

    return(
        <>

<Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - About| One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune | Camping Places Near Pune
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | About | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.jpg",  
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>
        <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3">About Us</h1>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


       {/* ==================== section fourth ================== */}
       <section
      className="about-us pt-0"
      style={{ backgroundImage: 'url(images/bg/bg-trans.png)' }}
    >
      <div className="container">
        <div className="about-image-box">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-lg-6 mb-4 pe-4">
              <div className="about-image overflow-hidden">
              <img src="glory stone images/Glowry Stone Resort/Glowry stone resort galler1.jpg" alt="Glowry Stone Resort" />
              </div>
            </div>
            <div className="col-lg-6 mb-4 ps-4">
              <div className="about-content text-center text-lg-start mb-4">
                <h4 className="theme d-inline-block mb-0">Get To Know Us</h4>
                <h2 className="border-b mb-2 pb-1">Explore Nature and Adventure at Glowry Stone Resort</h2>
                <p className="border-b mb-2 pb-4">
                At Glowry Stone Resort, we invite you to experience the beauty of nature and the thrill of adventure, all while enjoying the comforts of a luxurious retreat. Located just outside Pune, in the lap of scenic hills and serene landscapes, our resort offers a perfect escape from the everyday hustle. Whether you’re looking for a peaceful retreat, an adventurous getaway, or a memorable family vacation, Glowry Stone Resort is your destination for rejuvenation and adventure.
                  <br />
                  <br />
 
                </p>
                <div className="about-listing">
                
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              {/* Counter */}
              <div className="counter-main w-75 float-end">
                <div className="counter p-4 pb-0 box-shadow bg-white rounded">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                      <div className="counter-item border-end pe-4">
                        <div className="counter-content">
                          <h2 className="value mb-0 theme">{experience}+</h2>
                          <span className="m-0">Hospitality Excellence</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                      <div className="counter-item border-end pe-4">
                        <div className="counter-content">
                          <h2 className="value mb-0 theme">{packages}+</h2>
                          <span className="m-0">Adventure & Nature Activities</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                      <div className="counter-item border-end pe-4">
                        <div className="counter-content">
                          <h2 className="value mb-0 theme">{customers}+</h2>
                          <span className="m-0">Happy Guests Every Year</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                      <div className="counter-item">
                        <div className="counter-content">
                          <h2 className="value mb-0 theme">{awards} <i class="bi bi-star-fill"></i></h2>
                          <span className="m-0">Service & Guest Reviews</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Counter */}
            </div>
          </div>
        </div>
      </div>
      <div className="white-overlay"></div>
    </section>

    <section
      id="expert-guides"
      style={{ backgroundColor: '#f4f4f9', padding: '50px 0' }}
    >
      <div
        className="container"
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 15px',
        }}
      >
        <h2
          style={{
            fontSize: '36px',
            color: 'var(--orange)',
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: '20px',
          }}
          >
          Expert Guides at Glowry Stone Resort
        </h2>
        <p
          style={{
            fontSize: '18px',
            color: '#555',
            textAlign: 'center',
            marginBottom: '40px',
          }}
        >
          Our expert guides ensure that your stay at Glowry Stone Resort is both
          enriching and memorable. From exploring local attractions to experiencing
          the beauty of Supa MIDC, our team is here to help you make the most of your
          visit.
        </p>

        <div
          className="guide-section"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            gap: '30px',
          }}
        >
          {/* Expert Agro-Tourism Guides */}
          <div
            className="guide-item"
            style={{
              flex: '1 1 calc(33% - 20px)',
              backgroundColor: '#fff',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              padding: '25px',
              transition: 'transform 0.3s ease',
              border:'2px solid var(--darkbrown)'
            }}
          >
            <h3
              style={{
                fontSize: '24px',
                color: 'var(--darkbrown)',
                fontWeight: 'bold',
                marginBottom: '15px',
              }}
            >
              Expert Guides for Local Exploration
            </h3>
            <p
              style={{
                fontSize: '16px',
                color: '#7f8c8d',
              }}
            >
              Our expert guides are passionate about the region's rich history and
              natural beauty. They’ll take you on curated tours to explore local
              attractions like Koyna Dam, Sajjangad Fort, and more.
            </p>
          </div>

          {/* Correct Tour Directions */}
          <div
            className="guide-item"
            style={{
              flex: '1 1 calc(33% - 20px)',
              backgroundColor: '#fff',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              padding: '25px',
              transition: 'transform 0.3s ease',
               border:'2px solid var(--darkbrown)'
            }}
          >
            <h3
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginBottom: '15px',
                color:'var(--darkbrown)'
              }}
            >
              Correct Tour Directions
            </h3>
            <p
              style={{
                fontSize: '16px',
                color: '#7f8c8d',
              }}
            >
              We ensure you're led on the best routes during your tours. Whether
              you're visiting nearby natural wonders or historical sites, our
              guides make sure you get the most convenient and enjoyable experience.
            </p>
          </div>

          {/* Save Time & Learn More */}
          <div
            className="guide-item"
            style={{
              flex: '1 1 calc(33% - 20px)',
              backgroundColor: '#fff',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              padding: '25px',
              transition: 'transform 0.3s ease',
               border:'2px solid var(--darkbrown)'
            }}
          >
            <h3
              style={{
                fontSize: '24px',
                 color:'var(--darkbrown)',
                fontWeight: 'bold',
                marginBottom: '15px',
              }}
            >
              Save Time & Learn More
            </h3>
            <p
              style={{
                fontSize: '16px',
                color: '#7f8c8d',
              }}
            >
              Maximize your time and learning with our guides, who share local
              knowledge and insights while ensuring you visit the best spots
              without missing a thing.
            </p>
          </div>
        </div>
      </div>
    </section>

    <Testimonial></Testimonial>
        </>
    )
}