import Slider from "react-slick";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import './Page.css';
import GallerySlider from "./GallerySlider";
import Contactbox from "./Contactbox";
import RecentPost from "./RecentPost";
import Contact from "./Contact";
import ContactSection from "./ContactSection";
import { Helmet } from 'react-helmet';



export default function MangoMahostavinpune(){

    const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is the Mango Mahotsav at Glowry Stone Resort?",
      answer: "The Mango Mahotsav at Glowry Stone Resort is a celebration of the mango season, where guests can indulge in various mango-themed activities, such as Aamras parties, mango tastings, and special mango-based dishes. The event celebrates the king of fruits with traditional mango dishes, live cultural performances, and fun competitions."
    },
    {
      question: "When is the Mango Mahotsav held at Glowry Stone Resort?",
      answer: "The Mango Mahotsav typically takes place during the mango season, which is from May to June. This is when the resort hosts its special Aamras parties and other mango-themed events, coinciding with the peak of mango harvest."
    },
    {
      question: "How far is Glowry Stone Resort from the Mango Mahotsav venue in Ahmednagar?",
      answer: "Glowry Stone Resort is located just 40 kilometers from the center of Ahmednagar, making it a short and convenient drive to the Mango Mahotsav venue. You can enjoy the festival during the day and return to the resort for a relaxing evening."
    },
    {
      question: "Can I enjoy an Aamras party during the Mango Mahotsav at the resort?",
      answer: "Yes, Glowry Stone Resort offers a special Aamras Party during the Mango Mahotsav, where guests can indulge in freshly prepared Aamras (mango pulp) served with traditional puris. It’s a highlight of the mango season and a delicious way to enjoy the fruits of the festival."
    },
    {
      question: "Are mango-based dishes available at Glowry Stone Resort during the Mango Mahotsav?",
      answer: "Absolutely! The resort offers a variety of mango-inspired dishes, from mango curries and salads to desserts like mango kulfi, mango tarts, and more. These delicious offerings are available throughout the Mango Mahotsav season."
    },
    {
      question: "Is Glowry Stone Resort family-friendly for attending the Mango Mahotsav?",
      answer: "Yes, Glowry Stone Resort is an excellent choice for families. The resort offers family-friendly accommodations and a variety of activities, such as nature walks, adventure sports, and mango-themed events that both adults and children can enjoy."
    },
    {
      question: "Can I book a stay at Glowry Stone Resort specifically for the Mango Mahotsav?",
      answer: "Yes, you can book your stay at Glowry Stone Resort during the Mango Mahotsav. The resort offers special packages for guests attending the Mango Mahotsav, including access to mango-themed events, meals, and activities."
    },
    {
      question: "What kind of accommodations does Glowry Stone Resort offer during the Mango Mahotsav?",
      answer: "Glowry Stone Resort provides a range of accommodations, including cozy cottages and comfortable tents. These are designed to ensure that guests enjoy a peaceful and relaxing stay while being close to the Mango Mahotsav events."
    },
    {
      question: "Are there other activities at Glowry Stone Resort during the Mango Mahotsav?",
      answer: "Yes! While attending the Mango Mahotsav, guests can also enjoy a variety of activities at Glowry Stone Resort, such as trekking, ATV rides, rock climbing, birdwatching, and yoga. The resort offers a perfect balance of adventure and relaxation."
    },
    {
      question: "How can I book a stay at Glowry Stone Resort during the Mango Mahotsav?",
      answer: "You can book your stay directly through the Glowry Stone Resort’s official website, by phone, or via email. It’s recommended to book in advance during the Mango Mahotsav season, as it is a popular time for visitors."
    }
  ];
  
  const testimonials = [
    {
      quote: "My family and I had the most wonderful experience at Glowry Stone Resort during the Mango Mahotsav. The resort truly captured the spirit of the festival with its amazing Aamras party and delicious mango-based dishes. We were treated to the freshest Aamras, served with puris, which was absolutely divine! The entire resort was beautifully decorated, and the live music performances added so much charm to the evening. Apart from the mango feast, we enjoyed nature walks, a serene boat ride by the lake, and just relaxing in the peaceful surroundings. Glowry Stone Resort made our Mango Mahotsav experience unforgettable. Highly recommended for anyone looking to enjoy the festival and a relaxing nature retreat!",
      author: "— Anjali & Rajesh Sharma, Pune"
    },
    {
      quote: "Glowry Stone Resort was the perfect place for our group to enjoy the Mango Mahotsav. The resort offered everything we were looking for—a fun celebration of mangoes, great food, and plenty of activities. The Aamras party was the highlight of our stay—refreshing mango pulp served in abundance, and the resort even had special mango-based desserts and drinks! After enjoying the festivities at the Mango Mahotsav, we could return to the resort and unwind with a nature walk or relax by the lake. The staff was warm and welcoming, and the entire experience was seamless. If you’re a mango enthusiast or just want to enjoy a peaceful weekend, this resort is the place to be!",
      author: "— Kunal & Priya Deshmukh, Mumbai"
    }
  ];
  
        
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
  
   

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "Event",
      "name": "Mango Mahotsav at Glowry Stone Resort",
      "image": "https://www.glowrystone.com/glory stone images/Glowry Stone Resort keyword/Mango Mohostav in Ahmednagar.jpg", // Replace with your actual image URL
      "description": "Celebrate Mango Mahotsav at Glowry Stone Resort, Ahmednagar! Enjoy an Aamras party, mango-themed events, and a unique experience with friends and family. A perfect place to indulge in delicious mango dishes and festivities.",
      "startDate": "2024-05-01", // Example date for the event
      "endDate": "2024-05-31",  // Example date for the event
      "location": {
        "@type": "Place",
        "name": "Glowry Stone Resort",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road",
          "addressLocality": "Parner",
          "addressRegion": "Maharashtra",
          "postalCode": "414302",
          "addressCountry": "IN"
        }
      },
      "offers": {
        "@type": "Offer",
        "url": "https://www.glowrystone.com/Mango-Mahotsav-in-Ahmednagar",
        "priceCurrency": "INR",
        "price": "Varies",  // Replace with actual pricing if needed
        "eligibleRegion": "IN",
        "eligibleQuantity": {
          "@type": "QuantitativeValue",
          "value": 100,
          "unitCode": "C62"
        }
      },
      "performer": {
        "@type": "Organization",
        "name": "Glowry Stone Resort",
        "sameAs": "https://www.glowrystone.com"
      },
      "url": "https://www.glowrystone.com",
      "sameAs": [
        "https://www.facebook.com/glowrystone",
        "https://www.instagram.com/glowrystone"
      ]
    };
  
    
  
    return(
        <>
           <Helmet>
      {/* Title Tag */}
      <title>
      Contact: +91 7066665554 / +91 9145557771 | Celebrate Mango Mahotsav at Glowry Stone Resort | Aamras Party Near Ahmednagar
      </title>

      {/* Meta Tags */}
      <meta
        name="description"
        content="Join us at Glowry Stone Resort for the Mango Mahotsav (Amba Mahotsav) and enjoy an Aamras party near Ahmednagar. A perfect family-friendly event to celebrate mangoes with delicious dishes and festivities."
      />
      <meta
        name="keywords"
        content="Mango Mahotsav in Ahmednagar, Amba Mahotsav at Glowry Stone Resort, Aamras Party near Ahmednagar, Mango Mahotsav near Ahmednagar, Best Place to Enjoy Aamras Party near Ahmednagar, Mango Mahotsav Glowry Stone Resort"
      />
      <meta name="author" content="Glowry Stone Resort" />
      <meta name="robots" content="index, follow" />

      {/* Open Graph for Social Sharing */}
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Mango Mahotsav at Glowry Stone Resort | Aamras Party Near Ahmednagar"
      />
      <meta
        property="og:description"
        content="Celebrate Mango Mahotsav (Amba Mahotsav) at Glowry Stone Resort, Ahmednagar! Join our Aamras party, enjoy mango-themed dishes, and experience fun activities with your family."
      />
      <meta property="og:url" content="https://www.glowrystone.com/Mango-Mahotsav-in-Ahmednagar" />
      <meta property="og:image" content="https://www.glowrystone.com/glory stone images/Glowry Stone Resort keyword/Mango Mohostav in Ahmednagar.jpg" />
      <meta property="og:site_name" content="Glowry Stone Resort" />

      {/* Contact Information */}
      <meta
        name="contact"
        content="For reservations and inquiries, call +91 7066665554 / +91 9145557771 or email reservation@glowrystone.com."
      />
      <meta
        name="address"
        content="Glowry Stone Resort, Wadegavhan, Ahmednagar-Nagar Road, Tal. Parner, Dist. Ahilya Nagar (Ahmednagar), Maharashtra - 414302, India"
      />
      <meta name="email" content="reservation@glowrystone.com" />
      <meta name="phone" content="+91 7066665554, +91 9145557771" />

      {/* Canonical Link */}
      <link rel="canonical" href="https://www.glowrystone.com/" />

      {/* JSON-LD Schema Markup */}
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
    <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3 text-white">Mango Mahotsav in Ahmednagar</h2>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


    {/* ========================= */}

    <section className="trending recent-articles pb-6 keywordsection">
      <div className="container">
      <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="glory stone images/Glowry Stone Resort keyword/Mango Mohostav in Ahmednagar.jpg" className="img-fluid" alt="" />
              </div>
              <h2>Glowry Stone Resort: Your Perfect Stay During the Mango Mahotsav in Ahmednagar</h2>
<p>Ahlmednagar is famous for its vibrant Mango Mahotsav, a celebration that draws mango lovers from all over the country. If you're planning to attend the Mango Mahotsav, Glowry Stone Resort is the ideal place to stay, offering a peaceful, nature-filled retreat just a short drive away from the event. Nestled in the picturesque surroundings of Ahmednagar, Glowry Stone Resort combines luxury with nature, providing a perfect escape for travelers who want to enjoy the Mango Mahotsav while also soaking in the tranquility of the great outdoors.</p>

<h3>Amba Mahotsav at Glowry Stone Resort: A Unique Mango Celebration Near Ahmednagar</h3>
<p>Glowry Stone Resort offers an unforgettable experience during the Amba Mahotsav, a special celebration of mangoes (locally known as "Amba"). Held annually, this event brings together mango enthusiasts to indulge in the finest varieties of mangoes and mango-based delicacies. The Amba Mahotsav at Glowry Stone Resort near Ahmednagar is a must-attend for anyone who loves this king of fruits. The festival features an array of activities, tastings, and cultural performances, making it an ideal destination for families, friends, and food lovers alike.</p>

<h3>Amba Mahotsav in Glowry Stone Resort, Ahmednagar</h3>
<p>The Amba Mahotsav at Glowry Stone Resort in Ahmednagar celebrates the rich mango heritage of the region. As part of the event, guests can savor a wide variety of mangoes, including regional favorites like Kesar, Langra, and Himsagar. The resort takes the festival to the next level by offering a special mango-themed menu and exclusive mango-based beverages. The resort’s lush, natural surroundings provide a perfect atmosphere for enjoying the festive spirit, making this Mahotsav not only a celebration of mangoes but also a retreat into nature.</p>

<h3>Aamras Party Near Ahmednagar at Glowry Stone Resort</h3>
<p>For those looking to enjoy the classic Aamras party, Glowry Stone Resort is the best place near Ahmednagar. The resort hosts vibrant Aamras parties during the mango season, where guests can indulge in the freshest, juiciest Aamras (mango pulp) served with puris and other traditional accompaniments. Whether you’re with family, friends, or colleagues, an Aamras party at Glowry Stone Resort is an unforgettable experience filled with delicious food, great company, and the joy of mangoes.</p>

<h3>Best Place to Enjoy Aamras Party Near Ahmednagar</h3>
<p>Looking for the best place to enjoy an Aamras party near Ahmednagar? Look no further than Glowry Stone Resort. Located just a short drive from Ahmednagar, this resort offers a perfect setting for a mango-themed celebration. The serene environment, combined with delicious Aamras and a variety of mango-based treats, makes it the ultimate destination for mango lovers. Whether you're a local or visiting Ahmednagar, the resort’s Aamras party is an experience you don’t want to miss.</p>

<h3>Aamras Party / Mango Mahotsav at Glowry Stone Resort</h3>
<p>At Glowry Stone Resort, the Aamras Party and Mango Mahotsav are the highlights of the mango season. During this time, the resort hosts a grand celebration where guests can enjoy a delicious spread of mango-inspired dishes. From Aamras served fresh and cold to mango curries and desserts, the festival is a paradise for mango lovers. The resort also organizes fun-filled events like mango-eating competitions, live music, and cultural performances, making it a lively and memorable occasion.</p>

<h3>Aamras Party Mango Mahotsav at Glowry Stone Resort</h3>
<p>The Aamras Party and Mango Mahotsav at Glowry Stone Resort are the perfect way to celebrate the arrival of mango season. The resort’s lush green setting, comfortable accommodations, and warm hospitality make it an ideal venue for enjoying this unique festival. Guests can indulge in various mango-themed activities, feast on Aamras, and experience the traditional celebrations associated with this beloved fruit. Whether you're a mango aficionado or just looking for a fun and tasty way to spend your weekend, Glowry Stone Resort offers an experience that’s both delicious and delightful.</p>

<h3>Glowry Stone Resort in Pune Contact Number:</h3>
<p>Contact Glowry Stone Resort at [+91 7066665554] for bookings or inquiries. Glowry Stone Resort is not just a picnic spot near Ahmednagar; it’s an experience that brings families closer to nature, provides them with endless opportunities to bond, and ensures a day of relaxation, fun, and enjoyment.</p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- Amba Mahotsav at Glowy Stone Resort</p>
<p>- Amba Mahotsav in Glowy Stone Resort Ahmednagar</p>
<p>- Aamras party near Ahmednagar</p>


                    </td>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- Best place to enjoy Aamras party near Ahmednagar</p>
<p>- Aamras Party / Mango Mahotsav at Glowy Stone Resort</p>
<p>- Aamras party Mango Mahotsav at Glowy Stone Resort</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>Why Choose Glowry Stone Resort for the Mango Mahotsav in Ahmednagar?</h2>
<p>If you're planning to experience the Mango Mahotsav in Ahmednagar, Glowry Stone Resort should be your top choice for accommodation and festivities. Here’s why:</p>

<h3>1. Proximity to Mango Mahotsav</h3>
<p>Glowry Stone Resort is located just a short drive from the heart of Ahmednagar, making it an ideal base for attending the Mango Mahotsav. After a day of indulging in mangoes, cultural performances, and local delicacies at the festival, you can retreat to the resort to relax in the peaceful, natural surroundings. Its proximity to the event ensures you’re never far from the action but still have a serene escape at the end of the day.</p>

<h3>2. A Unique Mango-Themed Experience</h3>
<p>At Glowry Stone Resort, the Mango Mahotsav isn’t just an event you attend—it’s an experience you live. The resort goes above and beyond by offering a dedicated Aamras Party and mango-themed meals during the festival. You can indulge in freshly made Aamras, savor different varieties of mangoes, and enjoy mango-based dishes and desserts that are exclusive to the season. It’s a mango lover's paradise!</p>

<h3>3. Stunning Natural Setting for Your Mango Festival Experience</h3>
<p>While the Mango Mahotsav in Ahmednagar is a vibrant celebration, Glowry Stone Resort offers a peaceful and picturesque escape. Set amidst lush greenery and rolling hills, the resort allows you to immerse yourself in the beauty of nature while still being close to the festival. You can relax by a tranquil lake, enjoy nature walks, or simply unwind in the comfortable cottages, making it the perfect blend of excitement and relaxation.</p>

<h3>4. Exciting Activities Beyond the Festival</h3>
<p>At Glowry Stone Resort, your visit to the Mango Mahotsav becomes part of a broader adventure. The resort offers a range of outdoor activities like trekking, rock climbing, ATV rides, and nature walks, allowing you to explore the scenic surroundings. You can combine the mango festival’s excitement with fun activities in nature, making it a complete getaway experience.</p>

<h3>5. Perfect for Couples, Families, and Groups</h3>
<p>Whether you’re attending the Mango Mahotsav with your partner, family, or friends, Glowry Stone Resort offers something for everyone. Couples can enjoy intimate lakeside dinners, families can indulge in fun mango-themed activities, and groups can bond over an exciting Aamras party or group adventure activities. The resort’s variety of accommodations and experiences ensures that every guest has a memorable time.</p>

<h3>6. Eco-Friendly and Sustainable Experience</h3>
<p>Glowry Stone Resort is committed to sustainability and eco-friendly practices. The resort’s serene, natural setting provides a perfect opportunity for guests to appreciate nature’s beauty while supporting responsible tourism. During the Mango Mahotsav, you can enjoy an authentic, environmentally-conscious experience, knowing that the resort takes care to preserve the natural surroundings.</p>

<h3>7. Delicious Mango-Based Delicacies</h3>
<p>Glowry Stone Resort elevates the Mango Mahotsav by offering a delectable spread of mango-inspired dishes throughout the festival. From Aamras served with crispy puris to mango desserts like mango kulfi, mango tarts, and mango salad, there’s something for every mango lover to enjoy. The resort’s chefs ensure that only the freshest mangoes are used in creating these delicious treats, adding an extra layer of flavor to your festival experience.</p>

<h3>8. Personalized Service and Hospitality</h3>
<p>The hospitality at Glowry Stone Resort is second to none. The staff is dedicated to ensuring your comfort, whether you're enjoying the Mango Mahotsav or simply relaxing in the peaceful environment of the resort. Their attention to detail and warm service make your stay even more enjoyable and memorable.</p>

              <div className="">
                <h2>
                FAQs for Glowry Stone Resort – Mango Mahotsav in Ahmednagar?
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

                {/* ======================== */}
                <div className="">
                  <h2>
                  Testimonial for Glowry Stone Resort – Mango Mahotsav in Ahmednagar:- {" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>

                <ContactSection></ContactSection>

            </div>
          <div class="col-12 col-md-4">
            <RecentPost></RecentPost>
              <Contactbox></Contactbox>
          </div> 
          </div>
      </div>
    </section>

  <GallerySlider></GallerySlider>

        </>
    )
}  